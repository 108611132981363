import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import { payments as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import LoadingSpinner from "../../../constants/loader";
import { useQuery } from "@tanstack/react-query";
import { fetchItems } from "../../../helpers/api/ApiService";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true
  },
  {
    Header: "Date",
    accessor: "date",
    sort: true
  },
  {
    Header: "Account Name",
    accessor: "account_name",
    sort: true
  },
  {
    Header: "Pay To",
    accessor: "pay_to",
    sort: true
  },
  {
    Header: "Description",
    accessor: "description",
    sort: false
  },
  {
    Header: "Amount",
    accessor: "amount",
    sort: true
  },
  {
    Header: "Status",
    accessor: "status",
    sort: true
  },
  {
    Header: "Action", accessor: "action", sort: false, Cell: ({ row }: { row: TableRow<any> }) => (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-action" style={{ borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <i className="bi-gear-wide-connected"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/accounts/payments/edit/${row.original.id}`}><Edit /> Edit</Dropdown.Item>
          <Dropdown.Item><Trash />Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

const PaymentList = () => {



  const {
    data: payments,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["payments"],
    queryFn: () => fetchItems("/payments"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  console.log(payments);

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },

  ];

  if (payments) {
    sizePerPageList.push({
      text: "All",
      value: payments.length,
    });
  }

  if (isLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Invoice List"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="page-title-box">
                <h4 className="page-title">
                  Payment List
                </h4>
                <div className="page-title-right">
                  <ButtonGroup className="ms-2 d-none d-sm-inline-block me-1">
                    <Link to="/accounts/payments/create" className="btn btn-primary">
                      <i className="uil uil-plus me-1"></i>Add Payment</Link>
                  </ButtonGroup>
                </div>
              </div>

              <Table
                columns={columns}
                data={payments}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default PaymentList;

import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { debitReports as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import LoadingSpinner from "../../../constants/loader";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchItems } from "../../../helpers/api/ApiService";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Date",
    accessor: "date",
    sort: true,
  },
  {
    Header: "Patient ID",
    accessor: "patient_id",
    sort: true,
  },
  {
    Header: "Account Name",
    accessor: "account_name",
    sort: false,
  },
  {
    Header: "Description",
    accessor: "description",
    sort: true,
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    sort: false,
  },
  {
    Header: "Price",
    accessor: "price",
    sort: false,
  }, 
  {
    Header: "Total",
    accessor: "total",
    sort: false,
  }, 
];

const sizePerPageList = [
  {
    text: "15",
    value: 15,
  },

  {
    text: "25",
    value: 25,
  },

];

const DebitReport = () => {

  const {
    data: debits,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["debit-report"],
    queryFn: () => fetchItems("/accounts/debit-report"),
    staleTime: 5 * 60 * 1000, 
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });



  if (isLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if(debits){
    sizePerPageList.push( {
      text: "All",
      value: debits.length,
    });
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Account Report"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={debits}
                pageSize={15}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
                haveFooter={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default DebitReport;

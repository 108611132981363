import React, { useEffect, useState } from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { products as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../../constants/loader";
import { toast } from "react-toastify";
import { fetchItems } from "../../../helpers/api/ApiService";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Name",
    accessor: "name",
    sort: true,
  },
  {
    Header: "SKU",
    accessor: "sku",
    sort: false,
  },
  {
    Header: "Initial",
    accessor: "initial",
    sort: true,
  },
  {
    Header: "Purchases",
    accessor: "purchases",
    sort: false,
  },
  {
    Header: "In",
    accessor: "in",
    sort: false,
  },
  {
    Header: "Out",
    accessor: "out",
    sort: false,
  },
  // {
  //   Header: "Consumption",
  //   accessor: "consumption",
  //   sort: false,
  // },
  {
    Header: "Stock",
    accessor: "stock",
    sort: false,
  },
  {
    Header: "Action", accessor: "action", sort: false, Cell: ({ row }: { row: TableRow<any> }) => (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-action" style={{ borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <i className="bi-gear-wide-connected"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/inventory/products/edit/${row.original.id}`}><Edit /> Edit</Dropdown.Item>
          <Dropdown.Item><Trash />Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

const ProductList = () => {

  const [products, setProducts] = useState<any[]>([]);
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    const fetchProducts = async () => {
      setisLoading(true);
      try {
        const fetchedProduct = await fetchItems("/products");
        setProducts(fetchedProduct);
      } catch (error) {
        toast.error(`Error fetching products: ${error}`);
      } finally {
        setisLoading(false);
      }
    };
    fetchProducts()
  }, [])



  if (isLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  const sizePerPageList = [
    {
      text: "15",
      value: 5,
    },
    {
      text: "25",
      value: 25,
    },

  ];

  if (products) {
    sizePerPageList.push({
      text: "All",
      value: products.length,
    });
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Product List"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>

              <div className="page-title-box">
                <h4 className="page-title">
                  Product List
                </h4>
                <div className="page-title-right">
                  <ButtonGroup className="ms-2 d-none d-sm-inline-block me-1">
                    <Link to="/inventory/products/create" className="btn btn-primary">
                      <i className="uil uil-plus me-1"></i>Add Product
                    </Link>
                  </ButtonGroup>
                </div>
              </div>

              <Table
                columns={columns}
                data={products}
                pageSize={15}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default ProductList;

import React, { useState, ChangeEvent, useEffect } from "react";
import { Card, Row, Col, Form, Button, Table } from "react-bootstrap";
import { fetchItem, fetchItems, updateItem } from "../../../helpers/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AutoComplete } from "primereact/autocomplete";
import LoadingSpinner from "../../../constants/loader";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

interface Patient {
    id: number;
    code: string;
    name: string;
    address: string;
}

interface Account {
    id: number;
    name: string;
    description: string;
}

interface InvoiceItem {
    account_id: string;
    account_name: string;
    description: string;
    quantity: string;
    price: string;
    subtotal: string;
}

interface Invoice {
    id: number;
    patient_id: number;
    vat: number;
    discount: number;
    paid: number;
    status: number;
    total: number;
    grand_total: number;
    items: InvoiceItem[];
}

const EditInvoice: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [filteredPatients, setFilteredPatients] = useState<Patient[]>([]);
    const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
    const [patientName, setPatientName] = useState<string>("");
    const [patientAddress, setPatientAddress] = useState<string>("");
    const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>([]);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editItem, setEditItem] = useState<InvoiceItem | null>(null);
    const [vatPercent, setVatPercent] = useState<number>(0);
    const [discountPercent, setDiscountPercent] = useState<number>(0);
    const [paidAmount, setPaidAmount] = useState<number>(0);
    const [invoiceStatus, setInvoiceStatus] = useState<number>(0);


    const queryClient = useQueryClient();

    // Fetching necessary data
    const { data: patientData, isLoading: isPatientLoading } = useQuery<Patient[]>({
        queryKey: ["patients/opt/edit"],
        queryFn: () => fetchItems("/patients/opt"),
        staleTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    });

    const { data: accounts, isLoading: isAccountLoading } = useQuery<Account[]>({
        queryKey: ["accounts/edit"],
        queryFn: () => fetchItems("/accounts"),
        staleTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    });

    queryClient.invalidateQueries({
        queryKey: ["account-invoice/edit", "accounts/edit", "patients/opt/edit"],
    });

    const { data: invoiceData, isLoading: isInvoiceLoading } = useQuery<Invoice>({
        queryKey: [`account-invoices/edit/${id}`],
        queryFn: () => fetchItem("/account-invoices", parseInt(id!)),
        staleTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    });




    useEffect(() => {
        if (invoiceData && patientData) {
            setInvoiceStatus(invoiceData.status);
            setVatPercent(invoiceData.vat);
            setDiscountPercent(invoiceData.discount);
            setPaidAmount(invoiceData.paid);

            console.log(invoiceData.vat);

            const patient = patientData.find((patient) => patient.id === invoiceData.patient_id);
            if (patient) {
                setSelectedPatient(patient);
                setPatientName(patient.name);
                setPatientAddress(patient.address);
            }
            const updatedInvoiceItems = invoiceData.items.map((item) => ({
                ...item,
                subtotal: calculateSubtotal(item.quantity, item.price).toFixed(2),
            }));
            setInvoiceItems(updatedInvoiceItems);
        }
    }, [invoiceData, patientData]);

    const calculateSubtotal = (quantity: string, price: string): number => {
        const parsedQuantity = parseFloat(quantity);
        const parsedPrice = parseFloat(price);
        return parsedQuantity * parsedPrice;
    };

    const handlePatientSelect = (e: any) => {
        const patient = e.value as Patient;
        setSelectedPatient(patient);
        setPatientName(patient.name);
        setPatientAddress(patient.address);
    };

    const handleAddItem = () => {
        setInvoiceItems([
            ...invoiceItems,
            { account_id: "", account_name: "", description: "", quantity: "", price: "", subtotal: "0.00" },
        ]);
    };

    const handleRemoveItem = (index: number) => {
        const updatedItems = [...invoiceItems];
        updatedItems.splice(index, 1);
        setInvoiceItems(updatedItems);
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        if (editingIndex !== null && editItem) {
            const updatedItem = {
                ...editItem,
                [name]: value,
            };
            updatedItem.subtotal = calculateSubtotal(updatedItem.quantity, updatedItem.price).toFixed(2);
            setEditItem(updatedItem);
        }
    };

    const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInvoiceStatus(parseInt(e.target.value));
        console.log(invoiceStatus);
    };

    const handleEditItem = (index: number) => {
        setEditingIndex(index);
        setEditItem({ ...invoiceItems[index] });
    };

    const handleSaveEdit = (index: number) => {
        if (editItem) {
            const updatedItems = [...invoiceItems];
            updatedItems[index] = { ...editItem };
            setInvoiceItems(updatedItems);
            setEditingIndex(null);
            setEditItem(null);
        }
    };

    const calculateTotal = (): number => {
        let total = 0;
        invoiceItems.forEach((item) => {
            total += parseFloat(item.subtotal);
        });
        return total;
    };

    const calculateVat = (): number => {
        return calculateTotal() * (vatPercent / 100);
    };

    const calculateDiscount = (): number => {
        return calculateTotal() * (discountPercent / 100);
    };

    const calculateGrandTotal = (): number => {
        return calculateTotal() + calculateVat() - calculateDiscount();
    };

    const calculateDue = (): number => {
        return calculateGrandTotal() - paidAmount;
    };

    const handleVatPercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const percent = parseInt(e.target.value);
        setVatPercent(percent);
    };

    const handleDiscountPercentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const percent = parseInt(e.target.value);
        setDiscountPercent(percent);
    };

    const handlePaidAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        const amount = parseInt(e.target.value);

        if (amount > calculateGrandTotal()) {
            setPaidAmount(calculateGrandTotal());
        } else {
            setPaidAmount(amount);
        }
    };

    const searchPatient = (event: { query: string }) => {
        const query = event.query.toLowerCase();
        setFilteredPatients(
            patientData?.filter((patient) => patient.code.toLowerCase().includes(query)) || []
        );
    };


    const [errors, setErrors] = useState<Partial<any>>({});
    const { mutate: update, isError, isPending } = useMutation({
        mutationFn: (newItem: any) => updateItem("/account-invoices", parseInt(id!), newItem),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [`account-invoices/edit/${id}`] });
            queryClient.invalidateQueries({ queryKey: ["account-invoices"] });
            toast.success("Account invoice updated successfully!");
            navigate("/accounts/invoice");
        },
        onError: (error) => {
            toast.error(`Error updating account invoice: ${(error as Error).message}`);
        },
    });



    const handleSave = (e: any) => {
        e.preventDefault();

        const invoiceData = {
            vat: vatPercent,
            discount: discountPercent,
            paid: paidAmount,
            status: invoiceStatus,
            invoice_items: invoiceItems.map(item => ({
                account_id: item.account_id,
                description: item.description,
                quantity: parseFloat(item.quantity),
                price: parseFloat(item.price)
            }))
        };

        console.log(invoiceData);

        update(invoiceData);

    };
    if (isInvoiceLoading || isPatientLoading || isAccountLoading) {
        return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="text-center mb-3">
                        <h3>Edit Invoice</h3>
                        <h5>
                            Demo Hospital Limited
                            <br />
                            House#25, 4th Floor, Mannan Plaza, Khilkhet, Dhaka-1229, Bangladesh.
                        </h5>
                    </div>
                    <Form onSubmit={handleSave}>
                        {/* Form inputs for patient details */}
                        <Form.Group as={Row} className="mb-2">
                            <Form.Label column md={2} className="text-end">
                                Patient ID
                            </Form.Label>
                            <Col md={4}>
                                <div className="card flex justify-content-center">
                                    <AutoComplete
                                        value={selectedPatient}
                                        suggestions={filteredPatients}
                                        completeMethod={searchPatient}
                                        field="code"
                                        onChange={handlePatientSelect}
                                        dropdown
                                        readOnly
                                        placeholder="Select Patient ID"
                                        className="invoice-input"
                                        aria-label="Patient ID"
                                    />
                                </div>
                                <Form.Text className="text-danger invlid_patient_id"></Form.Text>
                            </Col>
                            <Form.Label column md={2} className="text-end">
                                Full Name
                            </Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    type="text"
                                    id="patient_name"
                                    className="invoice-input"
                                    readOnly
                                    value={patientName}
                                    required
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={2} className="text-end">
                                Address
                            </Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    type="text"
                                    id="patient_address"
                                    className="invoice-input"
                                    value={patientAddress}
                                    readOnly
                                    required
                                />
                            </Col>
                            <Form.Label column md={2} className="text-end">
                                Date
                            </Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    type="date"
                                    className="invoice-input"
                                    placeholder="dd/mm/yyyy"
                                    readOnly
                                    required
                                />
                            </Col>
                        </Form.Group>

                        {/* Invoice Items Table */}
                        <Table striped hover className="mb-3">
                            <thead className="bg-primary custom-table-header">
                                <tr>
                                    <th>Account Name</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Sub Total</th>
                                    <th>Add / Remove / Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceItems.map((item, index) => (
                                    <tr key={index}>
                                        {editingIndex === index ? (
                                            <>
                                                <td>
                                                    <Form.Select
                                                        name="account_id"
                                                        value={editItem?.account_id || ""}
                                                        onChange={handleInputChange}
                                                        className="dont-select-me"
                                                        required
                                                    >
                                                        <option value="" disabled>
                                                            Select Option
                                                        </option>
                                                        {accounts?.map((account) => (
                                                            <option key={account.id} value={account.id}>
                                                                {account.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name="description"
                                                        value={editItem?.description || ""}
                                                        onChange={handleInputChange}
                                                        className="form-control"
                                                        placeholder="Description"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        name="quantity"
                                                        value={editItem?.quantity || ""}
                                                        onChange={handleInputChange}
                                                        required
                                                        autoComplete="off"
                                                        className="totalCal form-control"
                                                        placeholder="Quantity"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        name="price"
                                                        value={editItem?.price || ""}
                                                        onChange={handleInputChange}
                                                        required
                                                        autoComplete="off"
                                                        className="totalCal form-control"
                                                        placeholder="Price"
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        name="subtotal"
                                                        value={editItem?.subtotal || ""}
                                                        readOnly
                                                        autoComplete="off"
                                                        className="subtotal form-control"
                                                        placeholder="Sub Total"
                                                    />
                                                </td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        variant="success"
                                                        className="btn-sm"
                                                        onClick={() => handleSaveEdit(index)}
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        variant="secondary"
                                                        className="btn-sm"
                                                        onClick={() => setEditingIndex(null)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td>{item.account_name}</td>
                                                <td>{item.description}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.price}</td>
                                                <td>{item.subtotal}</td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        variant="warning"
                                                        className="btn-sm"
                                                        onClick={() => handleEditItem(index)}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        variant="danger"
                                                        className="btn-sm"
                                                        onClick={() => handleRemoveItem(index)}
                                                    >
                                                        Remove
                                                    </Button>
                                                    {index === invoiceItems.length - 1 && (
                                                        <Button
                                                            type="button"
                                                            variant="primary"
                                                            className="btn-sm"
                                                            onClick={handleAddItem}
                                                        >
                                                            Add
                                                        </Button>
                                                    )}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className="bg-info">
                                    <td colSpan={3}></td>
                                    <th className="text-end">Total</th>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            name="total"
                                            id="total"
                                            className="form-control"
                                            readOnly
                                            required
                                            placeholder="Total"
                                            value={calculateTotal().toFixed(2)}
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th colSpan={3} className="text-end">
                                        Vat (%)
                                    </th>
                                    <td colSpan={2}>
                                        <Form.Control
                                            type="number"
                                            id="vatPercent"
                                            name="vatPercent"
                                            required
                                            autoComplete="off"
                                            className="form-control"
                                            value={vatPercent.toString()}
                                            // defaultValue={vatPercent.toString()}
                                            onChange={handleVatPercentChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            name="vat"
                                            id="vat"
                                            required
                                            autoComplete="off"
                                            className="vatDiscount paidDue form-control"
                                            placeholder="Vat"
                                            value={calculateVat().toFixed(2)}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={3} className="text-end">
                                        Discount (%)
                                    </th>
                                    <td colSpan={2}>
                                        <Form.Control
                                            type="number"
                                            id="discountPercent"
                                            required
                                            autoComplete="off"
                                            className="form-control"
                                            value={discountPercent.toString()}
                                            onChange={handleDiscountPercentChange}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            name="discount"
                                            required
                                            autoComplete="off"
                                            id="discount"
                                            className="vatDiscount paidDue form-control"
                                            placeholder="Discount"
                                            value={calculateDiscount().toFixed(2)}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                                <tr className="bg-success">
                                    <td colSpan={3}></td>
                                    <th className="text-end">Grand Total</th>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            name="grand_total"
                                            readOnly
                                            required
                                            autoComplete="off"
                                            id="grand_total"
                                            className="paidDue form-control"
                                            placeholder="Grand Total"
                                            value={calculateGrandTotal().toFixed(2)}
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}></td>
                                    <th className="text-end">Paid</th>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            name="paid"
                                            id="paid"
                                            autoComplete="off"
                                            className="paidDue form-control"
                                            required
                                            placeholder="Paid"
                                            value={paidAmount.toString()}
                                            onChange={handlePaidAmountChange}
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr className="bg-danger">
                                    <td colSpan={3}></td>
                                    <th className="text-end">Due</th>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            name="due"
                                            id="due"
                                            autoComplete="off"
                                            className="paidDue form-control"
                                            required
                                            placeholder="Due"
                                            value={calculateDue().toFixed(2)}
                                            readOnly
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>
                                        <Form.Group as={Row}>
                                            <Form.Label column xs={3}>
                                                Status
                                            </Form.Label>
                                            <Col xs={9}>
                                                <Form.Check
                                                    inline
                                                    label="Active"
                                                    type="radio"
                                                    name="status"
                                                    id="status_active"
                                                    value="1"
                                                    checked={invoiceStatus == 1}
                                                    onChange={handleStatusChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Inactive"
                                                    type="radio"
                                                    name="status"
                                                    id="status_inactive"
                                                    value="0"
                                                    checked={invoiceStatus == 0}
                                                    onChange={handleStatusChange}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Button type="reset" variant="info" className="btn-block">
                                            Reset
                                        </Button>
                                    </td>
                                    <td>
                                        <Button type="submit" variant="success" className="btn-block">
                                           {isPending?"Saving...":"Save"}
                                        </Button>
                                    </td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
};

export default EditInvoice;
import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer } from '@react-pdf/renderer';
import { fetchItem } from '../helpers/api/ApiService';
import { toast } from 'react-toastify';
import { Row } from 'react-bootstrap';
import LoadingSpinner from '../constants/loader';

const ShowReport = () => {

  const [isLoading, setLoading] = useState(false);
  const [report, setReport] = useState<any>(null);

  useEffect(() => {
    const accountId = parseInt("14");

    const fetchInvoice = async () => {
      setLoading(true);
      try {
        const fetchedInvoice = await fetchItem("/report-pdf", accountId);
        setReport(fetchedInvoice);
      } catch (error) {
        toast.error(`Error fetching account: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, []);

  interface ComponentData {
    id: string;
    name: string;
    reference_range: string;
    result: string | number;
  }

  interface TestData {
    id: string;
    name: string;
    components: ComponentData[];
  }

  if (isLoading || !report) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner /></Row>;
  }


  return (
    <>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          <Page style={styles.page} orientation="portrait">
            <View style={styles.header} fixed>
              <Text style={styles.headerText}>Company Logo</Text> {/* Placeholder for a company logo or header text */}
            </View>
            <View style={styles.body}>

              <View style={[{ borderWidth: 1, borderColor: "grey", padding: "3" }]}>
                <View style={styles.row}>
                  <View style={[styles.rowH, { width: "30%" }]}>
                    <Text style={[styles.label, { textAlign: "left" }]}>Invoice No</Text>
                    <Text style={[styles.value, { textAlign: "left", marginLeft: "10" }]}>: {report.barcode}</Text>
                  </View>
                  <View style={styles.rowH}>
                    <Text style={[styles.label, { width: "50%", textAlign: 'right' }]}>Invoice Date</Text>
                    <Text style={[styles.value, { width: "50%" }]}>: {report.date}</Text>
                  </View>
                  <View style={[styles.rowH, { width: "24%" }]}>
                    <Text style={[styles.label, { width: "50%", textAlign: 'right' }]}>Delivery Date</Text>
                    <Text style={[styles.value, { width: "50%", textAlign: "left", marginLeft: "10" }]}>: {report.delivery_date}</Text>
                  </View>
                  <View style={[styles.rowH, { width: "24%" }]}>
                    <Text style={[styles.label, { textAlign: "right" }]}>Report No</Text>
                    <Text style={styles.value}>: {report.report_no}</Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={[styles.rowH, { width: "52%" }]}>
                    <Text style={[styles.label, { textAlign: "left", width: "21.8%" }]}>Patient Name</Text>
                    <Text style={[styles.value, { width: "78.2%", textAlign: "left", marginLeft: "10" }]}>: {report.client_name}</Text>
                  </View>

                  <View style={[styles.rowH, { width: "24%" }]}>
                    <Text style={[styles.label, { width: "50%", textAlign: 'right' }]}>Gender</Text>
                    <Text style={[styles.value, { width: "50%", textAlign: "left", marginLeft: "10" }]}>: {report.gender}</Text>
                  </View>
                  <View style={[styles.rowH, { width: "24%" }]}>
                    <Text style={[styles.label, { textAlign: "right" }]}>Age</Text>
                    <Text style={[styles.value,{marginLeft:"10",textAlign:"left"}]}>: {32}</Text>
                  </View>
                </View>

                <View style={[styles.row, { marginBottom: 0 }]}>
                  <View style={[styles.rowH, { width: "100%" }]}>
                    <Text style={[styles.label, { textAlign: "left", width: "11.2%" }]}>Referred By</Text>
                    <Text style={[styles.value, { width: "87.8%", textAlign: "left", marginLeft: "10" }]}>: S2424343434</Text>
                  </View>
                </View>

              </View>

              <View style={[{ height: 5 }]}></View>

              <View style={[{ padding: 3, borderWidth: 1, borderColor: "grey" }]}>
                <View style={styles.row}>
                  <View style={[styles.rowH, { width: "52%" }]}>
                    <Text style={[styles.label, { textAlign: "left", width: "21.8%" }]}>Sample</Text>
                    <Text style={[styles.value, { width: "78.2%", textAlign: "left", marginLeft: "10" }]}>: Blood</Text>
                  </View>

                  <View style={[styles.rowH, { width: "48%" }]}>
                    <Text style={[styles.label, { textAlign: "right", width: "23%" }]}>LAB No</Text>
                    <Text style={[styles.value, { width: "77%", textAlign: "left", marginLeft: "10" }]}>: 22324433434</Text>
                  </View>
                </View>

                <View style={[styles.row, { marginBottom: 0 }]}>
                  <View style={[styles.rowH, { width: "100%" }]}>
                    <Text style={[styles.label, { textAlign: "left", width: "11%" }]}>LAB No</Text>
                    <Text style={[styles.value, { width: "89%", textAlign: "left", marginLeft: "10" }]}>: 22324433434</Text>
                  </View>
                </View>

              </View>


              <View style={[{ height: 10 }]}></View>

              <View style={[styles.row, { borderWidth: 1, borderColor: "grey" }]}>
                <Text style={[styles.value, { width: "36%", textAlign: "center" }]}>Test</Text>
                <View style={[styles.separator]}></View>
                <Text style={[styles.value, { width: "28%", textAlign: "center" }]}>Result</Text>
                <View style={[styles.separator]}></View>
                <Text style={[styles.value, { width: "36%", textAlign: "center" }]}>Reference Value</Text>
              </View>

              <View style={[{ height: 5 }]}></View>

              {report.tests.map((test: TestData) => (
                <React.Fragment key={test.id}>
                  <Text style={[styles.value, { textAlign: 'left', width: "100%", marginBottom: 5 }]}>
                    {test.name}
                  </Text>
                  {test.components.map((component: ComponentData) => (
                    <TestResultRow
                      key={component.id}
                      testName={component.name}
                      referenceValue={component.reference_range}
                      result={component.result}
                    />
                  ))}
                </React.Fragment>
              ))}


            </View>
            <View style={styles.footer} fixed>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )} fixed />
            </View>
          </Page>
        </Document >
      </PDFViewer>
    </>
  )
};

interface TestResultRowProps {
  testName: string;
  result: string | number;
  referenceValue: string | number;
}

// The TestResultRow component with typed props
const TestResultRow: React.FC<TestResultRowProps> = ({ testName, result, referenceValue }) => {
  return (
    <View style={[styles.row, { borderBottomWidth: 1, borderBottomStyle: 'dotted', borderBottomColor: "grey", padding: 4 }]}>
      <Text style={[styles.label, { width: "36%", textAlign: "left" }]}>
        {testName}
      </Text>
      <Text style={[styles.label, { width: "28%", textAlign: "center" }]}>
        {result}
      </Text>
      <Text style={[styles.label, { width: "36%", textAlign: "right" }]}>
        {referenceValue}
      </Text>
    </View>
  );
};
Font.register({
  family: 'Arima',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1YTA-pQGOyYw2fw.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1hjc-pQGOyYw2fw.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-ERBrEdwcoaKww.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 0,
    paddingBottom: 120,
  },
  body: {
    // paddingTop: 20,
    paddingBottom: 60,
    paddingHorizontal: 40,
    justifyContent: 'center', // Center vertically within the page
    alignItems: 'center', // Center horizontally within the page
  },
  row: {
    flexDirection: 'row',
    marginBottom: 5,
    justifyContent: 'center', // Center horizontally within the row
    alignItems: 'center', // Center vertically within the row
  },
  rowH: {
    width: "22%",
    flexDirection: 'row',
    margin: 0,
    justifyContent: 'center', // Center horizontally within the rowH
    alignItems: 'center', // Center vertically within the rowH
  },
  label: {
    fontSize: 9,
    width: "40%",
    fontFamily: 'Arima',
    textAlign: 'center', // Center text within label
  },
  value: {
    fontSize: 8,
    width: "60%",
    fontWeight: 'bold',
    fontFamily: 'Arima',
    textAlign: 'center', // Center text within value
  },
  header: {
    flexDirection: "row",
    justifyContent: "center", // Center horizontally within the header
    alignItems: "center", // Center vertically within the header
    height: 80,
    marginBottom: 15,
    backgroundColor: "grey",
  },
  headerText: {
    fontSize: 16,
    color: 'white',
    fontFamily: 'Arima',
    textAlign: 'center', // Center text within headerText
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 40,
    backgroundColor: 'grey',
    justifyContent: 'center', // Center horizontally within the footer
    alignItems: 'center', // Center vertically within the footer
  },
  pageNumber: {
    fontSize: 12,
    color: 'white',
    textAlign: 'center', // Center text within pageNumber
  },
  separator: {
    width: 0.5,
    backgroundColor: 'grey',
    height: 18,
  },
});

export default ShowReport;

import React from "react";
import { Navigate, Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import InvoiceList from "../components/AccountManager/InvoiceList/InvoiceList";
import AccountReport from "../components/AccountManager/Report/Report";
import SupplierList from "../components/Inventory/Supplier/SupplierList";
import ProductList from "../components/Inventory/Product/ProductList";
import PurchaseList from "../components/Inventory/Purchase/PurchaseList";
import AdjustmentList from "../components/Inventory/Adjustment/AdjustmentList";
import TransferList from "../components/Inventory/Transfer/TransferList";
import AccountList from "../components/AccountManager/Accounts/AccountList";
import AddAccount from "../components/AccountManager/Accounts/AddAccounts";
import AddPayment from "../components/AccountManager/Payment/AddPayment";
import PaymentList from "../components/AccountManager/Payment/PaymentList";
import AddInvoiceForm from "../components/AccountManager/InvoiceList/AddInvoice";
import DebitReport from "../components/AccountManager/Report/DeditReport";
import CreditReport from "../components/AccountManager/Report/CreditReport";
import AddSupplier from "../components/Inventory/Supplier/AddSupplier";
import AddProduct from "../components/Inventory/Product/AddProduct";
import AddPurchases from "../components/Inventory/Purchase/AddPurchases";
import AddAdjustment from "../components/Inventory/Adjustment/AddAdjustments";
import AddTransfer from "../components/Inventory/Transfer/AddTransfer";
import EditAccount from "../components/AccountManager/Accounts/EditAccount";
import EditInvoice from "../components/AccountManager/InvoiceList/EditInvoice";
import EditPayment from "../components/AccountManager/Payment/EditPayment";
import EditSupplier from "../components/Inventory/Supplier/EditSupplier";
import EditProduct from "../components/Inventory/Product/EditProduct";
import EditPurchases from "../components/Inventory/Purchase/EditPurchase";
import EditAdjustment from "../components/Inventory/Adjustment/EditAdjustment";
import EditTransfer from "../components/Inventory/Transfer/EditTransfer";
import RenderPdf from "../components/RenderPdf";
import ShowReport from "../components/RenderLabReport";
// import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));

// dashboard
const EcommerceDashboard = React.lazy(
  () => import("../pages/dashboard/Ecommerce/")
);
const AnalyticsDashboard = React.lazy(
  () => import("../pages/dashboard/Analytics/")
);

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
const Projects = React.lazy(() => import("../pages/apps/Projects/"));
const ProjectDetail = React.lazy(
  () => import("../pages/apps/Projects/Detail/")
);
// - chat
const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));
// - email
const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"));
const EmailCompose = React.lazy(() => import("../pages/apps/Email/Compose"));
// - tasks
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));
// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
// -other
const Starter = React.lazy(() => import("../pages/other/Starter"));
const Profile = React.lazy(() => import("../pages/other/Profile"));
const Activity = React.lazy(() => import("../pages/other/Activity"));
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));

// uikit
const UIElements = React.lazy(() => import("../pages/uikit"));

// widgets
const Widgets = React.lazy(() => import("../pages/widgets/"));

// icons
const Unicons = React.lazy(() => import("../pages/icons/Unicons"));
const FeatherIcons = React.lazy(() => import("../pages/icons/Feather/"));
const BootstrapIcon = React.lazy(() => import("../pages/icons/Bootstrap/"));

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// charts
const Charts = React.lazy(() => import("../pages/charts/"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = React.lazy(() => import("../pages/maps/VectorMaps"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="accounts/list" />,
      route: PrivateRoute,
    },
    {
      path: "/dashboard/ecommerce",
      name: "Ecommerce",
      element: <EcommerceDashboard />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/list",
      name: "Accounts",
      element: <AccountList />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/create",
      name: "Add Accounts",
      element: <AddAccount />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/edit/:id",
      name: "Edit Accounts",
      element: <EditAccount />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/invoice",
      name: "Invoice List",
      element: <InvoiceList />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/invoice/create",
      name: "Create Invoice",
      element: <AddInvoiceForm />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/invoice/edit/:id",
      name: "Edit Invoice",
      element: <EditInvoice />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/payments",
      name: "Payment List",
      element: <PaymentList />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/payments/create",
      name: "Add Payment",
      element: <AddPayment />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/payments/edit/:id",
      name: "Edit Payment",
      element: <EditPayment />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/report",
      name: "Report",
      element: <AccountReport />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/debit-report",
      name: "Debit Report",
      element: <DebitReport />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/credit-report",
      name: "Credit Report",
      element: <CreditReport />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/suppliers",
      name: "Suppliers",
      element: <SupplierList />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/suppliers/create",
      name: "Add Suppliers",
      element: <AddSupplier />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/suppliers/edit/:id",
      name: "Edit Suppliers",
      element: <EditSupplier />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/products",
      name: "Products",
      element: <ProductList />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/products/create",
      name: "Add Product",
      element: <AddProduct />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/products/edit/:id",
      name: "Edit Product",
      element: <EditProduct />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/purchases",
      name: "Purchase",
      element: <PurchaseList />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/purchases/create",
      name: "Add Purchase",
      element: <AddPurchases />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/purchases/edit/:id",
      name: "Edit Purchase",
      element: <EditPurchases />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/adjustments",
      name: "Adjustment",
      element: <AdjustmentList />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/adjustments/create",
      name: "Add Adjustment",
      element: <AddAdjustment />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/adjustments/edit/:id",
      name: "Edit Adjustment",
      element: <EditAdjustment />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/transfers",
      name: "Transfer",
      element: <TransferList />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/transfers/create",
      name: "Add Transfer",
      element: <AddTransfer />,
      route: PrivateRoute,
    },
    {
      path: "/inventory/transfers/edit/:id",
      name: "Edit Transfer",
      element: <EditTransfer />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/lab-invoice",
      name: "Lab Invoice",
      element: <RenderPdf />,
      route: PrivateRoute,
    },
    {
      path: "/accounts/lab-report",
      name: "Lab Invoice",
      element: <ShowReport />,
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  element: <CalendarApp />,
  header: "Apps",
};

const chatAppRoutes: RoutesProps = {
  path: "/apps/chat",
  name: "Chat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "message-square",
  element: <ChatApp />,
};

const emailAppRoutes: RoutesProps = {
  path: "/apps/email",
  name: "Email",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/apps/email/inbox",
      name: "Inbox",
      element: <Inbox />,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/details",
      name: "Email Details",
      element: <EmailDetail />,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/compose",
      name: "Compose Email",
      element: <EmailCompose />,
      route: PrivateRoute,
    },
  ],
};

const projectAppRoutes: RoutesProps = {
  path: "/apps/projects",
  name: "Projects",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-briefcase",

  children: [
    {
      path: "/apps/projects/list",
      name: "List",
      element: <Projects />,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/details",
      name: "Detail",
      element: <ProjectDetail />,
      route: PrivateRoute,
    },
  ],
};

const taskAppRoutes: RoutesProps = {
  path: "/apps/tasks",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: "/apps/tasks/list",
      name: "Task List",
      element: <TaskList />,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/kanban",
      name: "Kanban",
      element: <Kanban />,
      route: PrivateRoute,
    },
  ],
};

const fileAppRoutes: RoutesProps = {
  path: "/apps/file-manager",
  name: "File Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  element: <FileManager />,
};

const appRoutes = [
  calendarAppRoutes,
  chatAppRoutes,
  emailAppRoutes,
  projectAppRoutes,
  taskAppRoutes,
  fileAppRoutes,
];

// pages
const extrapagesRoutes: RoutesProps = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      element: <Starter />,
      route: PrivateRoute,
    },
    {
      path: "/pages/profile",
      name: "Profile",
      element: <Profile />,
      route: PrivateRoute,
    },
    {
      path: "/pages/activity",
      name: "Activity",
      element: <Activity />,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      element: <Invoice />,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      element: <Pricing />,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes: RoutesProps = {
  path: "/components",
  name: "Components",
  icon: "package",
  header: "UI Elements",
  children: [
    {
      path: "/components/ui-elements",
      name: "UIElements",
      element: <UIElements />,
      route: PrivateRoute,
    },
    {
      path: "/components/widgets",
      name: "Widgets",
      element: <Widgets />,
      route: PrivateRoute,
    },
    {
      path: "/icons",
      name: "Icons",
      children: [
        {
          path: "/icons/unicons",
          name: "Unicons",
          element: <Unicons />,
          route: PrivateRoute,
        },
        {
          path: "/icons/feather",
          name: "Feather",
          element: <FeatherIcons />,
          route: PrivateRoute,
        },
        {
          path: "/icons/bootstrap",
          name: "Bootstrap Icon",
          element: <BootstrapIcon />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/forms",
      name: "Forms",
      children: [
        {
          path: "/forms/basic",
          name: "Basic Elements",
          element: <BasicForms />,
          route: PrivateRoute,
        },
        {
          path: "/forms/advanced",
          name: "Form Advanced",
          element: <FormAdvanced />,
          route: PrivateRoute,
        },
        {
          path: "/forms/validation",
          name: "Form Validation",
          element: <FormValidation />,
          route: PrivateRoute,
        },
        {
          path: "/forms/wizard",
          name: "Form Wizard",
          element: <FormWizard />,
          route: PrivateRoute,
        },
        {
          path: "/forms/upload",
          name: "File Upload",
          element: <FileUpload />,
          route: PrivateRoute,
        },
        {
          path: "/forms/editors",
          name: "Editors",
          element: <Editors />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/components/charts",
      name: "Charts",
      element: <Charts />,
      route: PrivateRoute,
    },
    {
      path: "/tables",
      name: "Tables",
      children: [
        {
          path: "/tables/basic",
          name: "Basic",
          element: <BasicTables />,
          route: PrivateRoute,
        },
        {
          path: "/tables/advanced",
          name: "Advanced",
          element: <AdvancedTables />,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/maps",
      name: "Maps",
      children: [
        {
          path: "/maps/googlemaps",
          name: "Google Maps",
          element: <GoogleMaps />,
          route: PrivateRoute,
        },
        {
          path: "/maps/vectorMaps",
          name: "Google Maps",
          element: <VectorMaps />,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    element: <Login />,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    element: <Register />,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    element: <Confirm />,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    element: <ForgetPassword />,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    element: <LockScreen />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/landing",
    name: "landing",
    element: <Landing />,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <Maintenance />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};

import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { Branch, branches as data } from "../data"; // Adjust the import based on your file structure
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createItem, fetchItems } from "../../../helpers/api/ApiService";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../constants/loader";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const navigate = useNavigate();
  // State for main product details
  const [formData, setFormData] = useState({
    name: '',
    sku: '',
    description: '',
  });

  // State for branch-specific data
  const [branchData, setBranchData] = useState(
    data.map(branch => ({
      branch_id: branch.id,
      name: branch.name,
      initial_quantity: 0,
      stock_alert: 0,
    }))
  );

  // State for form errors
  const [formErrors, setFormErrors] = useState({
    name: '',
    sku: '',
    description: ''
  });

  // Handle change for main product fields
  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle change for branch-specific fields
  const handleBranchChange = (e: any, index: number, field: string) => {
    const value = Number(e.target.value);
    setBranchData(prevBranchData => {
      const updatedBranchData = [...prevBranchData];
      updatedBranchData[index] = {
        ...updatedBranchData[index],
        [field]: value
      };
      return updatedBranchData;
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      name: '',
      sku: '',
      description: '',
      branches: ''
    };

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      toast.error(errors.name);
      isValid = false;
    }

    if (!formData.sku.trim()) {
      errors.sku = "SKU is required";
      toast.error(errors.sku);
      isValid = false;
    }

    if (!formData.description.trim()) {
      errors.description = "Description is required";
      toast.error(errors.description);
      isValid = false;
    }

    const hasValidQuantity = branchData.some(branch => branch.initial_quantity > 0);
    if (!hasValidQuantity) {
      errors.branches = "At least one branch must have an initial quantity greater than 0.";
      toast.error("At least one branch must have an initial quantity greater than 0.");
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const queryClient = useQueryClient();
  const { mutate: create, isError, isPending } = useMutation({
    mutationFn: (newItem: any) => createItem("/products", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      toast.success("Product added successfully!");
      setFormData({
        name: '',
        sku: '',
        description: ''
      });
      setBranchData(
        branches.map((branch: {
          branch_id: any; name: any;
        }) => ({
          branch_id: branch.branch_id,
          name: branch.name,
          initial_quantity: 0,
          stock_alert: 0,
        }))
      );
      setFormErrors({
        name: '',
        sku: '',
        description: ''
      });
      navigate("/inventory/products");
    },
    onError: (error) => {
      toast.error(`Error creating Product: ${(error as Error).message}`);
    },
  });

  // Handle form submit
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      // Prepare the data to be submitted


      const validBranches = branchData.filter(branch => branch.initial_quantity > 0);
      console.log("Branches with Initial Quantity > 0:", validBranches);

      const newProduct = {
        ...formData,
        stocks: validBranches
      };
      // Log the new product data
      console.log("New Product Data:", newProduct);
      create(newProduct);
      // Reset the form (optional)
      
    }
  };

  const {
    data: branches,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["branches"],
    queryFn: () => fetchItems("/branches"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if(branches){
    setBranchData(
      branches.map((branch: {
        id: any; name: any;
      }) => ({
        branch_id: branch.id,
        name: branch.name,
        initial_quantity: 0,
        stock_alert: 0,
      }))
    );
  }
  }, [branches])



  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Product"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Product</h4>

              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={"6"} className="mb-3">
                    <Form.Group>
                      <Form.Label htmlFor="name">Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={"6"} className="mb-3">
                    <Form.Group>
                      <Form.Label htmlFor="sku">SKU</Form.Label>
                      <Form.Control
                        type="text"
                        name="sku"
                        id="sku"
                        placeholder="SKU"
                        value={formData.sku}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.sku}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.sku}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="description">Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    id="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={handleInputChange}
                    isInvalid={!!formErrors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                <Card>
                  <Card.Body>
                    <h4>Branches</h4>
                    <Row className="mb-3">
                      <Col md={"4"} className="text-center fw-bold">Branch Name</Col>
                      <Col md={"4"} className="text-center fw-bold">Initial Quantity</Col>
                      <Col md={"4"} className="text-center fw-bold">Stock Alert</Col>
                    </Row>
                    {branchData.map((branch, index) => (
                      <Row key={index}>
                        <Row className="mb-3">
                          <Col md={"4"}>{branch.name}</Col>
                          <Col md={"4"}>
                            <Form.Control
                              type="number"
                              name="initial_quantity"
                              defaultValue={branch.initial_quantity}
                              onChange={(e) => handleBranchChange(e, index, 'initial_quantity')}
                              placeholder="Initial Quantity"
                              min={0}
                            />
                          </Col>
                          <Col md={"4"}>
                            <Form.Control
                              type="number"
                              name="stock_alert"
                              defaultValue={branch.stock_alert}
                              onChange={(e) => handleBranchChange(e, index, 'stock_alert')}
                              placeholder="Stock Alert"
                              min={0}
                            />
                          </Col>
                        </Row>
                        {index !== (branchData.length - 1) && <hr />}
                      </Row>
                    ))}
                  </Card.Body>
                </Card>

                <Button variant="primary" type="submit" disabled={isPending}>
                  {isPending ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const sizePerPageList = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "All",
    value: data.length,
  },
];

export default AddProduct;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Toast } from "react-bootstrap";
import PageTitle from "../../PageTitle";
import { Plus, Minus } from 'feather-icons-react';
import { ToastContentProps, toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createItem, fetchItems } from "../../../helpers/api/ApiService";
import { useNavigate } from "react-router-dom";

const AddPurchases = () => {
  interface ProductRow {
    id: number;
    product: string;
    unitPrice: number;
    quantity: number;
    totalPrice: number;
  }

  interface PaymentRow {
    id: number;
    date: string;
    amount: number;
    method: string;
  }

  // State variables
  const [productRows, setProductRows] = useState<ProductRow[]>([{ id: 1, product: "", unitPrice: 0, quantity: 0, totalPrice: 0 }]);
  const [paymentRows, setPaymentRows] = useState<PaymentRow[]>([{ id: 1, date: "", amount: 0, method: "" }]);
  const [subtotal, setSubtotal] = useState(0);
  const [vatPercentage, setVatPercentage] = useState<number | string>(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [paid, setPaid] = useState(0);
  const [due, setDue] = useState(0);
  const [purchaseDate, setPurchaseDate] = useState("");
  const [branchId, setBranchId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [note, setNote] = useState("");
  const navigate = useNavigate();

  // Toast state variables
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // Validation state variables
  const [errors, setErrors] = useState({
    purchaseDate: "",
    branchId: "",
    supplierId: "",
    products: "",
    payments: "",
    vatPercentage: ""
  });


  const {
    data: branches,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["branches"],
    queryFn: () => fetchItems("/branches"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
  const {
    data: suppliers,
    isLoading: supplierLoading,
  } = useQuery({
    queryKey: ["suppliers"],
    queryFn: () => fetchItems("/suppliers"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });


  const {
    data: products,
    isLoading: productsLoading,
  } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchItems("/products"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });


  // Handle adding a new product row
  const handleAddProduct = () => {
    const newRowId = productRows.length + 1;
    setProductRows([...productRows, { id: newRowId, product: "", unitPrice: 0, quantity: 0, totalPrice: 0 }]);
  };

  // Handle removing a product row
  const handleRemoveProduct = (idToRemove: number) => {
    const updatedRows = productRows.filter((row) => row.id !== idToRemove);
    setProductRows(updatedRows);
  };

  // Handle changes in product row inputs
  const handleProductChange = (id: number, field: keyof ProductRow, value: any) => {
    const updatedRows = productRows.map((row) => {
      if (row.id === id) {
        const newRow = { ...row, [field]: value };
        if (field === "unitPrice" || field === "quantity") {
          newRow.totalPrice = (newRow.unitPrice * newRow.quantity) || 0;
        }
        return newRow;
      }
      return row;
    });
    setProductRows(updatedRows);
  };

  // Handle adding a new payment row
  const handleAddPayment = () => {
    const newRowId = paymentRows.length + 1;
    setPaymentRows([...paymentRows, { id: newRowId, date: "", amount: 0, method: "" }]);
  };

  // Handle removing a payment row
  const handleRemovePayment = (idToRemove: number) => {
    const updatedRows = paymentRows.filter((row) => row.id !== idToRemove);
    setPaymentRows(updatedRows);
  };

  // Handle changes in payment row inputs
  const handlePaymentChange = (id: number, field: keyof PaymentRow, value: any) => {
    const updatedRows = paymentRows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setPaymentRows(updatedRows);
  };

  // Calculate the summary details
  useEffect(() => {
    // Calculate subtotal
    const calculatedSubtotal = productRows.reduce((sum, row) => sum + (row.totalPrice || 0), 0);
    setSubtotal(calculatedSubtotal);

    // Ensure VAT percentage is a number and at least 0
    const validVatPercentage = isNaN(Number(vatPercentage)) ? 0 : Math.max(0, Number(vatPercentage));

    // Calculate VAT amount
    const calculatedVatAmount = (calculatedSubtotal * validVatPercentage) / 100;
    setVatAmount(calculatedVatAmount);

    // Calculate total
    const calculatedTotal = calculatedSubtotal + calculatedVatAmount;
    setTotal(calculatedTotal);

    // Calculate paid amount from payment rows
    const calculatedPaid = paymentRows.reduce((sum, row) => sum + (row.amount || 0), 0);
    setPaid(calculatedPaid);

    // Calculate due amount
    const calculatedDue = calculatedTotal - calculatedPaid;
    setDue(calculatedDue);
  }, [productRows, paymentRows, vatPercentage]);

  // Handle VAT input change
  const handleVatChange = (value: string) => {
    // Ensure VAT is numeric and set to 0 if empty or invalid
    const newVatPercentage = value.trim() === "" ? 0 : parseFloat(value);
    setVatPercentage(isNaN(newVatPercentage) ? 0 : newVatPercentage);
  };

  // Validate form fields

  const validateFields = () => {
    const newErrors: any = {};

    if (!purchaseDate) newErrors.purchaseDate = "Purchase date is required.";
    if (!branchId) newErrors.branchId = "Branch selection is required.";
    if (!supplierId) newErrors.supplierId = "Supplier selection is required.";

    productRows.forEach((row, index) => {
      if (!row.product) newErrors[`product${index + 1}`] = `Product selection is required for row ${index + 1}.`;
      if (row.unitPrice < 0) newErrors[`unitPrice${index + 1}`] = `Unit price cannot be negative for row ${index + 1}.`;
      if (row.quantity < 0) newErrors[`quantity${index + 1}`] = `Quantity cannot be negative for row ${index + 1}.`;
    });

    paymentRows.forEach((row, index) => {
      if (!row.date) newErrors[`paymentDate${index + 1}`] = `Payment date is required for row ${index + 1}.`;
      if (!row.method) newErrors[`paymentMethod${index + 1}`] = `Payment method is required for row ${index + 1}.`;
      if (row.amount < 1) newErrors[`paymentAmount${index + 1}`] = `Payment amount cannot be negative for row ${index + 1}.`;
    });

    if (parseFloat(`${vatPercentage}`) < 0) newErrors.vatPercentage = "VAT percentage cannot be negative.";

    setErrors(newErrors);

    // Show toast if there are errors
    if (Object.keys(newErrors).length > 0) {
      Object.values(newErrors).forEach((error: any) => {
        toast.error(error); // Displaying each validation error as a toast
      });
    }

    return Object.keys(newErrors).length === 0;
  };


  const queryClient = useQueryClient();
  const { mutate: create, isError, isPending } = useMutation({
    mutationFn: (newItem: any) => createItem("/purchases", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["purchases","products"] });
      toast.success("Purchase added successfully!");

      navigate("/inventory/purchases");
    },
    onError: (error) => {
      toast.error(`Error creating purchase: ${(error as Error).message}`);
    },
  });

  // Handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate fields before submission
    if (!validateFields()) {
      console.log("Form validation failed.");
      return;
    }

    // Map products to the required format
    const items = productRows.map((row) => ({
      product_id: row.product ? parseInt(row.product) : null, // Assuming product value is the product ID
      quantity: row.quantity,
      unit_price: row.unitPrice
    }));

    // Map payments to the required format
    const payments = paymentRows.map((row) => ({
      date: row.date,
      amount: row.amount,
      method: row.method
    }));

    // Log the final result in the specified format
    const formData = {
      date: purchaseDate,
      branch_id: parseInt(branchId),
      supplier_id: parseInt(supplierId),
      vat: vatPercentage,
      paid: paid,
      note: note,
      items: items,
      payments: payments
    };

    create(formData);

    console.log("Form submitted:", formData);
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Purchases"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Purchases</h4>

              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="date"
                        value={purchaseDate}
                        onChange={(e) => setPurchaseDate(e.target.value)}
                        isInvalid={!!errors.purchaseDate}
                      />
                      <Form.Control.Feedback type="invalid">{errors.purchaseDate}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Branch</Form.Label>
                      <Form.Control
                        as="select"
                        value={branchId}
                        onChange={(e) => setBranchId(e.target.value)}
                        isInvalid={!!errors.branchId}
                      >

                        <option value="" disabled>Select Branch</option>
                        {branches && branches.map((branch: any) => (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.branchId}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Supplier</Form.Label>
                      <Form.Control
                        as="select"
                        value={supplierId}
                        onChange={(e) => setSupplierId(e.target.value)}
                        isInvalid={!!errors.supplierId}
                      >
                        <option value="" disabled>Select Supplier</option>
                        {suppliers && suppliers.map((supplier: any) => (
                          <option key={supplier.id} value={supplier.id}>
                            {supplier.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.supplierId}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={3} className="mb-3">
                    <Form.Group>
                      <Form.Label>Note</Form.Label>
                      <Form.Control
                        as="textarea"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        rows={1}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Products Table */}
                <Row>
                  <Col>
                    <Card className="card-primary card-outline">
                      <Card.Header>
                        <Row className="mb-1">
                          <Col className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0 header-title mt-0">Products</h5>
                            <Button
                              type="button"
                              className="btn btn-primary add_product"
                              onClick={handleAddProduct}
                            >
                              <Plus />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <table className="table table-bordered" id="products_table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Unit Price</th>
                              <th>Quantity</th>
                              <th>Total Price</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {productRows.map((row) => (
                              <tr key={row.id}>
                                <td>
                                  <Form.Control
                                    as="select"
                                    value={row.product}
                                    placeholder="Product"
                                    onChange={(e) => handleProductChange(row.id, 'product', e.target.value)}
                                    isInvalid={!!errors.products}
                                  >
                                    <option value="" disabled>Select Product</option>
                                    {products && products.map((product: any) => (
                                      <option key={product.id} value={product.id}>
                                        {product.name}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    value={row.unitPrice}
                                    placeholder="Unit Price"
                                    onChange={(e) => handleProductChange(row.id, 'unitPrice', parseFloat(e.target.value))}
                                    isInvalid={!!errors.products}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    value={row.quantity}
                                    placeholder="Quantity"
                                    onChange={(e) => handleProductChange(row.id, 'quantity', parseFloat(e.target.value))}
                                    isInvalid={!!errors.products}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    value={row.totalPrice}
                                    placeholder="Total Price"
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <Button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveProduct(row.id)}
                                  >
                                    <Minus />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {/* Payments Table */}
                <Row>
                  <Col>
                    <Card className="card-primary card-outline">
                      <Card.Header>
                        <Row className="mb-1">
                          <Col className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0 header-title mt-0">Payments</h5>
                            <Button
                              type="button"
                              className="btn btn-primary add_payment"
                              onClick={handleAddPayment}
                            >
                              <Plus />
                            </Button>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <table className="table table-bordered" id="payments_table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Amount</th>
                              <th>Payment method</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentRows.map((row) => (
                              <tr key={row.id}>
                                <td>
                                  <Form.Control
                                    type="date"
                                    value={row.date}
                                    placeholder="Date"
                                    onChange={(e) => handlePaymentChange(row.id, 'date', e.target.value)}
                                    isInvalid={!!errors.payments}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    value={row.amount}
                                    placeholder="Amount"
                                    onChange={(e) => handlePaymentChange(row.id, 'amount', parseFloat(e.target.value))}
                                    isInvalid={!!errors.payments}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    as="select"
                                    value={row.method}
                                    placeholder="Payment Method"
                                    onChange={(e) => handlePaymentChange(row.id, 'method', e.target.value)}
                                    isInvalid={!!errors.payments}
                                  >
                                    <option value="">Select Method</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Credit Card">Credit Card</option>
                                    <option value="Bank Transfer">Bank Transfer</option>
                                  </Form.Control>
                                </td>
                                <td>
                                  <Button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemovePayment(row.id)}
                                  >
                                    <Minus />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {/* Purchase Summary */}
                <Row>
                  <Col>
                    <Card className="card-primary">
                      <Card.Body className="p-0">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <th>Subtotal</th>
                              <td>
                                <Form.Control
                                  type="number"
                                  value={subtotal.toFixed(2)}
                                  placeholder="Subtotal"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>VAT (%)</th>
                              <td>
                                <Form.Control
                                  type="number"
                                  value={vatPercentage}
                                  placeholder="VAT Percentage"
                                  onChange={(e) => handleVatChange(e.target.value)}
                                  isInvalid={!!errors.vatPercentage}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>VAT Amount</th>
                              <td>
                                <Form.Control
                                  type="number"
                                  value={vatAmount.toFixed(2)}
                                  placeholder="VAT Amount"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Total</th>
                              <td>
                                <Form.Control
                                  type="number"
                                  value={total.toFixed(2)}
                                  placeholder="Total"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Paid</th>
                              <td>
                                <Form.Control
                                  type="number"
                                  value={paid.toFixed(2)}
                                  placeholder="Paid"
                                  readOnly
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Due</th>
                              <td>
                                <Form.Control
                                  type="number"
                                  value={due.toFixed(2)}
                                  placeholder="Due"
                                  readOnly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                {/* Submit Button */}
                <Row>
                  <Col className="mt-3">
                    <Button variant="primary" type="submit" disabled={isPending}>
                      {isPending ? "Submitting..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddPurchases;
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import PageTitle from "../../PageTitle";
import { updateItem, fetchItem, fetchItems, createItem } from "../../../helpers/api/ApiService";
import LoadingSpinner from "../../../constants/loader";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

interface Payment {
  id: number;
  date: string;
  account_name: string;
  account_id: string;
  pay_to: string;
  description: string;
  amount: number;
  status: string;
}

interface Account {
  id: number;
  name: string;
  description: string;
}

const EditPayment: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [payment, setPayment] = useState<Payment | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<{ id: number, name: string }>({ id: 0, name: '' });
  const [payTo, setPayTo] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const { data: accounts, error: accountsError, isLoading: isAccountLoading } = useQuery<Account[]>({
    queryKey: ["accounts"],
    queryFn: () => fetchItems("/accounts"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const paymentId = parseInt(id!);

    const fetchPayment = async () => {
      try {
        const fetchedPayment = await fetchItem(`/payments`,paymentId);
        setPayment(fetchedPayment);
        setSelectedAccount({ id: fetchedPayment.account_id, name: fetchedPayment.account_name });

      } catch (error) {
        console.error("Error fetching payment:", error);
        navigate("/accounts/payments"); // Redirect to payment list or error page
      }
    };

    fetchPayment();
  }, [id, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (payment) {
      setPayment({
        ...payment,
        [name]: value,
      });
    }
  };

  const handleValidation = () => {
    let errors: { [key: string]: string } = {};

    if (!selectedAccount.id) {
      errors.accountName = "Account name is required";
    }

    if (!payment?.pay_to) {
      errors.payTo = "Pay To field is required";
    }

    if (!payment?.amount || payment?.amount <= 0) {
      errors.amount = "Amount must be greater than zero";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const queryClient = useQueryClient();
  const { mutate: update, isError, isPending } = useMutation({
      mutationFn: (newItem: any) => updateItem("/payments",parseInt(id!), newItem),
      onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["payments"] });
          toast.success("Payment updated successfully!");
          navigate("/accounts/payments");
      },
      onError: (error) => {
          toast.error(`Error creating Payment: ${(error as Error).message}`);
      },
  });


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (handleValidation()) {

      const data={
        date:payment?.date,
        account_id: selectedAccount.id,
        pay_to:payment?.pay_to,
        description:payment?.description,
        amount:payment?.amount,
        status:payment?.status=="1"?true:false,
      };
      console.log("Form submitted:", data);
      update(data);
    } else {
      toast.error(`Form has errors. Please correct them.${formErrors}`);
    }
  };

  if (!payment) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner /></Row>;
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Edit Payment"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Edit Payment</h4>

              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="date">Date</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="date"
                      id="date"
                      type="date"
                      readOnly
                      value={payment.date}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="accountName">
                    Account Name
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Select
                      id="accountName"
                      name="accountName"
                      value={selectedAccount.id.toString()}
                      onChange={(e: any) => {
                        const selectedId = parseInt(e.target.value);
                        const selectedName = accounts?.find(acc => acc.id === selectedId)?.name || '';
                        setSelectedAccount({ id: selectedId, name: selectedName });
                      }}
                      className="dont-select-me"
                      required
                      isInvalid={!!formErrors.accountName}
                    >
                      <option value="0" disabled>
                        Select Option
                      </option>
                      {accounts?.map((acc: Account) => (
                        <option value={acc.id.toString()} key={acc.id}>{acc.name}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formErrors.accountName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="pay_to">Pay To</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="pay_to"
                      id="pay_to"
                      type="text"
                      value={payment.pay_to}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="description">Description</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="description"
                      id="description"
                      as="textarea"
                      rows={5}
                      value={payment.description}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="amount">Amount</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="amount"
                      id="amount"
                      type="number"
                      value={payment.amount}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2}>Status</Form.Label>
                  <Col lg={7}>
                    <Row>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="active"
                          name="status"
                          label="Active"
                          value="1"
                          checked={payment.status === "1"}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="inactive"
                          name="status"
                          label="Inactive"
                          value="0"
                          checked={payment.status === "0"}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isPending}>
                  {isPending ? "Saving..." : "Save Changes"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditPayment;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Toast } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import PageTitle from "../../PageTitle";
import { updateItem } from "../../../helpers/api/ApiService"; // Import the updateItem function
import { fetchItem } from "../../../helpers/api/ApiService"; // Import the fetchItem function if needed
import { accountsArray as data } from "../data";
import { useQueryClient } from "@tanstack/react-query";
import LoadingSpinner from "../../../constants/loader";
import { toast } from "react-toastify";

interface Account {
  id: number;
  name: string;
  description: string;
  type: string;
  date: string;
  status: string;
}

const EditAccount = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [account, setAccount] = useState<Account | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const accountId = parseInt(id!); // Use ! to assert id is not undefined

    const fetchAccount = async () => {
      try {
        const fetchedAccount = await fetchItem("/accounts", accountId);
        setAccount(fetchedAccount);
      } catch (error) {
        toast.error(`Error fetching account: ${error}`);
        navigate("/accounts");
      }
    };

    fetchAccount();
  }, [id, navigate]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (account) {
      setAccount({
        ...account,
        [name]: value,
      });
    }
  };
  const queryClient = useQueryClient();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (account) {
      setLoading(true);
      try {
        const updatedAccount = await updateItem("/accounts", account.id, account);
        queryClient.invalidateQueries({ queryKey: ["accounts"] });
        console.log("Updated Account:", updatedAccount);
        const updatedData = data.map((acc) =>
          acc.id === account.id ? updatedAccount : acc
        );
        console.log("Updated Data:", updatedData);
        toast.success("Account updated successfully");
        navigate("/accounts/list");
      } catch (error) {
        toast.error(`Error updating account: ${error}`);
      } finally {
        setLoading(false);
      }
    }
  };

  if (!account) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Edit Account"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Edit Account</h4>

              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="name">Account Name</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="name"
                      id="name"
                      placeholder="Account Name"
                      value={account.name}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="type">Type</Form.Label>
                  <Col lg={6}>
                    <Form.Select
                      name="type"
                      id="type"
                      value={account.type}
                      onChange={(e) => handleChange(e as React.ChangeEvent<HTMLSelectElement>)}
                    >
                      <option value="debit">Debit</option>
                      <option value="credit">Credit</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="description">Description</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="description"
                      id="description"
                      placeholder="Description"
                      value={account.description}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2}>Status</Form.Label>
                  <Col lg={7}>
                    <Row>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="active"
                          name="status"
                          label="Active"
                          value="active"
                          checked={account.status === "active"}
                          onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
                        />
                      </Col>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="inactive"
                          name="status"
                          label="Inactive"
                          value="inactive"
                          checked={account.status === "inactive"}
                          onChange={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>

                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? "Saving..." : "Save Changes"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditAccount;

import React, { useRef, useEffect, forwardRef, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  PluginHook,
} from "react-table";
import classNames from "classnames";

// components
import Pagination from "./Pagination";

interface GlobalFilterProps {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
  searchBoxClass: any;
}

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchBoxClass,
}: GlobalFilterProps) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState<any>(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className={classNames(searchBoxClass)}>
      <span className="d-flex align-items-center">
        Search :{" "}
        <input
          type="search"
          value={value || ""}
          onChange={(e: any) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          className="form-control w-auto ms-1"
        />
      </span>
    </div>
  );
};

interface IndeterminateCheckboxProps {
  indeterminate: any;
  children?: React.ReactNode;
}

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          ref={resolvedRef}
          {...rest}
        />
        <label htmlFor="form-check-input" className="form-check-label"></label>
      </div>
    </>
  );
});

interface TableProps {
  isSearchable?: boolean;
  isSortable?: boolean;
  pagination?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  sizePerPageList?: {
    text: string;
    value: number;
  }[];
  haveFooter?: boolean; // New prop for footer
  columns: {
    Header: string;
    accessor: string;
    sort?: boolean;
    Cell?: any;
    className?: string;
    width?: string; // New width property
  }[];
  data: any[];
  pageSize?: any;
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
}

const Table = (props: TableProps) => {
  const {
    isSearchable = false,
    isSortable = false,
    pagination = false,
    isSelectable = false,
    isExpandable = false,
    sizePerPageList = [],
    haveFooter = false, // Default to false if not provided
  } = props;

  let hooks: PluginHook<any>[] = [];
  if (isSearchable) hooks.push(useGlobalFilter);
  if (isSortable) hooks.push(useSortBy);
  if (isExpandable) hooks.push(useExpanded);
  if (pagination) hooks.push(usePagination);
  if (isSelectable) hooks.push(useRowSelect);

  const dataTable = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: { pageSize: props.pageSize || 10 },
    },
    ...hooks
  );

  let rows = pagination ? dataTable.page : dataTable.rows;
  let footerData: any = null;
  let displayRows = rows;

  if (haveFooter && props.data.length > 0) {
    footerData = props.data[props.data.length - 1];
    displayRows = rows.slice(0, -1); // Exclude the last row from display
  }

  return (
    <>
      {isSearchable && (
        <GlobalFilter
          preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
          globalFilter={dataTable.state.globalFilter}
          setGlobalFilter={dataTable.setGlobalFilter}
          searchBoxClass={props.searchBoxClass}
        />
      )}

      <div className="table-responsive">
        <table
          {...dataTable.getTableProps()}
          className={classNames(
            "table table-centered react-table",
            props.tableClass
          )}
        >
          <thead className={props.theadClass}>
            {dataTable.headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps(
                      column.sort && column.getSortByToggleProps()
                    )}
                    className={classNames({
                      sorting_desc: column.isSortedDesc === true,
                      sorting_asc: column.isSortedDesc === false,
                      sortable: column.sort === true,
                    })}
                    style={{ width: column.width || "auto" }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...dataTable.getTableBodyProps()}>
            {displayRows.map((row: any, rowIndex: number) => {
              dataTable.prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell: any, cellIndex: number) => (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                        },
                      ])}
                      key={cellIndex}
                    >
                      {typeof cell.value === "number" && !isNaN(cell.value)
                        ? Number.isInteger(cell.value)
                          ? cell.value // Display integer as is
                          : cell.value.toFixed(2) // Display float with 2 decimal places
                        : cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          {haveFooter && footerData && (
            <tfoot>
              <tr>
                {dataTable.columns.map((column: any, columnIndex: number) => (
                  <td
                    {...column.getFooterProps()}
                    key={columnIndex}
                    style={{
                      fontWeight: "bold", // Apply bold style to footer
                      width: column.width || "auto",
                    }}
                  >
                    {typeof footerData[column.id] === "number" &&
                    !isNaN(footerData[column.id])
                      ? Number.isInteger(footerData[column.id])
                        ? footerData[column.id] // Display integer as is
                        : footerData[column.id].toFixed(2) // Display float with 2 decimal places
                      : footerData[column.id]}
                  </td>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      {pagination && (
        <Pagination tableProps={dataTable} sizePerPageList={sizePerPageList} />
      )}
    </>
  );
};

export default Table;

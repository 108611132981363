import React, { useEffect, useState } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { Plus, Minus } from 'feather-icons-react';
import PageTitle from "../../PageTitle";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchItem, fetchItems, updateItem } from "../../../helpers/api/ApiService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../../constants/loader";

interface ProductRow {
    id: number;
    product: string;
    product_id: string;
    quantity: number;
    type: string;
    note: string;
}

const EditAdjustment = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [productRows, setProductRows] = useState<ProductRow[]>([{ id: 1, product_id: "", product: "", quantity: 0, type: "", note: "" }]);
    const [date, setDate] = useState("");
    const [branch, setBranch] = useState("");
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [adjustment, setAdjustment] = useState<any>();

    const {
        data: branches,
        error,
        isLoading,
    } = useQuery({
        queryKey: ["branches"],
        queryFn: () => fetchItems("/branches"),
        staleTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    });

    const {
        data: products,
        isLoading: productsLoading,
    } = useQuery({
        queryKey: ["products"],
        queryFn: () => fetchItems("/products"),
        staleTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        const aId = parseInt(id!);

        const fetchAdjustment = async () => {
            setDetailsLoading(true);
            try {
                const fetchedAdjustment = await fetchItem("/adjustments", aId);
                setAdjustment(fetchedAdjustment);
                console.log(fetchedAdjustment);
                setDate(fetchedAdjustment.date);
                setBranch(fetchedAdjustment.branch_id.toString());
                setProductRows(fetchedAdjustment.adjustment_items);
            } catch (error) {
                toast.error(`Error fetching adjustment: ${error}`);
                navigate("/inventory/adjustments");
            } finally {
                setDetailsLoading(false);
            }
        };

        fetchAdjustment();
    }, [id, navigate]);

    const handleAddProduct = () => {
        const newRowId = productRows.length + 1;
        setProductRows([...productRows, { id: newRowId, product_id: "", product: "", quantity: 0, type: "", note: "" }]);
    };

    const handleRemoveProduct = (idToRemove: number) => {
        const updatedRows = productRows.filter((row) => row.id !== idToRemove);
        setProductRows(updatedRows);
    };

    const handleProductChange = (id: number, field: keyof ProductRow, value: any) => {
        console.log(value);
        const updatedRows = productRows.map((row) => {   
            if (row.id === id) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setProductRows(updatedRows);
    };

    const queryClient = useQueryClient();
    const { mutate: update, isError, isPending } = useMutation({
        mutationFn: (updatedItem: any) => updateItem("/adjustments", parseInt(id!), updatedItem),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["adjustments"] });
            toast.success("Adjustment updated successfully!");
            navigate("/inventory/adjustments");
        },
        onError: (error) => {
            toast.error(`Error updating adjustment: ${(error as Error).message}`);
        },
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let isValid = true;
        let errorMsg = "";

        if (!date) {
            isValid = false;
            errorMsg += "Date is required.\n";
        }

        if (!branch) {
            isValid = false;
            errorMsg += "Branch is required.\n";
        }

        const selectedProducts = new Set();
        let i =0;
        for (const row of productRows) {
            console.log(row);
            i=i+1;
            if (!row.product_id) {
                isValid = false;
                errorMsg += `Product selection is required for row ${i}.\n`;
            } 
            /* else if (selectedProducts.has(row.product_id)) {
                isValid = false;
                errorMsg += `Product in row ${i} is duplicated.\n`;
            }*/
            else {
                selectedProducts.add(row.product);
            }

            if (row.quantity <= 0) {
                isValid = false;
                errorMsg += `Quantity must be greater than zero for row ${i}.\n`;
            }

            if (!row.type) {
                isValid = false;
                errorMsg += `Type selection is required for row ${i}.\n`;
            }
        }

        if (isValid) {
            const submissionData = {
                date: date,
                branch_id: parseInt(branch),
                products: productRows.map(row => ({
                    product_id: parseInt(row.product_id),
                    quantity: row.quantity,
                    type: row.type,
                    note: row.note
                }))
            };

            update(submissionData);

            console.log("Submitting data:", submissionData);

        } else {
            toast.error(`Validation errors:\n${errorMsg}`);
        }
    };

    if (isLoading || detailsLoading) {
        return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
    }

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "Tables", path: "/features/tables/advanced" },
                    {
                        label: "Advanced Tables",
                        path: "/features/tables/advanced",
                        active: true,
                    },
                ]}
                title={"Edit Adjustment"}
            />
            <Card>
                <Card.Body>

                    <h4 className="mb-3 header-title mt-0">Edit Adjustment</h4>

                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>Branch</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="branch_id"
                                        value={branch}
                                        onChange={(e) => setBranch(e.target.value)}
                                        required>
                                        <option value="" disabled >{isLoading ? "Loading Branches..." : "Select branch"}</option>
                                        {branches && branches.map((branch: any) => (
                                            <option key={branch.id} value={branch.id}>
                                                {branch.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="card-primary card-outline">
                                    <Card.Header>
                                        <Row className="mb-1">
                                            <Col className="d-flex justify-content-between align-items-center">
                                                <h4 className="mb-0 header-title mt-0">Products</h4>
                                                <Button
                                                    type="button"
                                                    className="btn btn-primary add_product"
                                                    onClick={handleAddProduct}
                                                >
                                                    <Plus />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <table className="table table-bordered" id="products_table">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Type</th>
                                                    <th>Note</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productRows.map((row) => {
                                                    console.log("Product Row:", row); // Logs each product row object

                                                    return (
                                                        <tr key={row.id}>
                                                            <td>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        as="select"
                                                                        name={`products[${row.id}][id]`}
                                                                        defaultValue={row.product_id}
                                                                        onChange={(e) => handleProductChange(row.id, 'product_id', e.target.value)}
                                                                        required
                                                                    >
                                                                        <option value="" disabled >{productsLoading ? "Loading Products..." : "Select Product"}</option>
                                                                        {products && products.map((product: any) => (
                                                                            <option key={product.id} value={product.id}>
                                                                                {product.name}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type="number"
                                                                        name={`products[${row.id}][quantity]`}
                                                                        value={row.quantity}
                                                                        min="1"
                                                                        onChange={(e) => handleProductChange(row.id, 'quantity', parseInt(e.target.value))}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        as="select"
                                                                        name={`products[${row.id}][type]`}
                                                                        value={row.type}
                                                                        onChange={(e) => handleProductChange(row.id, 'type', e.target.value)}
                                                                        required
                                                                    >
                                                                        <option value="" disabled>Type</option>
                                                                        <option value="In">In</option>
                                                                        <option value="Out">Out</option>
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        name={`products[${row.id}][note]`}
                                                                        value={row.note}
                                                                        rows={1}
                                                                        placeholder="Note"
                                                                        onChange={(e) => handleProductChange(row.id, 'note', e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                    onClick={() => handleRemoveProduct(row.id)}
                                                                >
                                                                    <Minus />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                        </table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Button variant="primary" type="submit" disabled={isPending}>
                            {isPending ? "Submitting..." : "Submit"}
                        </Button>
                    </Form>

                </Card.Body>
            </Card>
        </>
    );
};

export default EditAdjustment;

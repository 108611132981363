import React, { useState, useEffect } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { Plus, Minus } from 'feather-icons-react';
import PageTitle from "../../PageTitle";
import { createItem, fetchItem, fetchItems, updateItem } from "../../../helpers/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../../constants/loader";
import { toast } from "react-toastify";

// Define the types for product rows
interface ProductRow {
    id: number;
    product_id: string;
    quantity: number;
    note: string;
}

const EditTransfer = () => {
    const { id } = useParams<{ id: string }>();
    const [productRows, setProductRows] = useState<ProductRow[]>([{ id: 1, product_id: "", quantity: 0, note: "" }]);
    const [date, setDate] = useState("");
    const [fromBranch, setFromBranch] = useState("");
    const [toBranch, setToBranch] = useState("");
    const [detailsLoading, setDetailsLoading] = useState(false);

    const navigate=useNavigate();

    const {
        data: branches,
        error,
        isLoading,
    } = useQuery({
        queryKey: ["branches"],
        queryFn: () => fetchItems("/branches"),
        staleTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    });

    const {
        data: products,
        isLoading: productsLoading,
    } = useQuery({
        queryKey: ["products"],
        queryFn: () => fetchItems("/products"),
        staleTime: 5 * 60 * 1000,
        refetchInterval: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    });


    useEffect(() => {
        const aId = parseInt(id!);

        const fetchTransfer = async () => {
            setDetailsLoading(true);
            try {
                const fetchedTransfer= await fetchItem("/transfers", aId);
                console.log(fetchedTransfer);
                setDate(fetchedTransfer.date);
                setFromBranch(fetchedTransfer.from_branch_id);
                setToBranch(fetchedTransfer.to_branch_id);
                setProductRows([]);
                setProductRows(fetchedTransfer.items);
            } catch (error) {
                toast.error(`Error fetching transfer: ${error}`);
                navigate("/inventory/transfers");
            } finally {
                setDetailsLoading(false);
            }
        };

        fetchTransfer();
    }, [id, navigate]);


    // Validation error states
    const [dateError, setDateError] = useState<string | null>(null);
    const [fromBranchError, setFromBranchError] = useState<string | null>(null);
    const [toBranchError, setToBranchError] = useState<string | null>(null);
    const [productErrors, setProductErrors] = useState<{ [key: number]: string }>({});

    // Handle adding a new product row
    const handleAddProduct = () => {
        const newRowId = productRows.length + 1;
        setProductRows([...productRows, { id: newRowId, product_id: "", quantity: 0, note: "" }]);
    };

    // Handle removing a product row
    const handleRemoveProduct = (idToRemove: number) => {
        const updatedRows = productRows.filter((row) => row.id !== idToRemove);
        setProductRows(updatedRows);
    };

    // Handle changes in product row inputs
    const handleProductChange = (id: number, field: keyof ProductRow, value: any) => {
        const updatedRows = productRows.map((row) => {
            if (row.id === id) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setProductRows(updatedRows);
    };


    const queryClient = useQueryClient();
    const { mutate: update, isError, isPending } = useMutation({
        mutationFn: (newItem: any) => updateItem("/transfers",parseInt(id!), newItem),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["transfers"] });
            toast.success("Transfers updated successfully!");
            navigate("/inventory/transfers");
        },
        onError: (error) => {
            toast.error(`Error updating transfer: ${(error as Error).message}`);
        },
    });

    // Handle form submission
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Validate form fields
        if (!validateForm()) {
            return;
        }

        const formData={date:date,from_branch_id:fromBranch,to_branch_id:toBranch,items:productRows};

        update(formData);


    };

    // Validate form fields
    const validateForm = (): boolean => {
        let isValid = true;

        // Validate date
        if (!date) {
            setDateError("Date is required");
            isValid = false;
        } else {
            setDateError(null);
        }

        // Validate fromBranch
        if (!fromBranch) {
            setFromBranchError("From Branch is required");
            isValid = false;
        } else {
            setFromBranchError(null);
        }

        // Validate toBranch
        if (!toBranch) {
            setToBranchError("To Branch is required");
            isValid = false;
        } else {
            setToBranchError(null);
        }

        // Validate product rows
        const newProductErrors: { [key: number]: string } = {};
        productRows.forEach((row) => {
            if (!row.product_id) {
                newProductErrors[row.id] = "Product is required";
                isValid = false;
            } else if (row.quantity <= 0) {
                newProductErrors[row.id] = "Quantity must be greater than 0";
                isValid = false;
            } else {
                newProductErrors[row.id] = "";
            }
        });
        setProductErrors(newProductErrors);

        return isValid;
    };

    // Handle the change in "From Branch" and "To Branch" and show toast if they are the same
    const handleFromBranchChange = (e: any) => {
        const selectedFromBranch = e.target.value;
        setFromBranch(selectedFromBranch);

        if (selectedFromBranch === toBranch) {
            toast.error("The 'From Branch' and 'To Branch' cannot be the same!");
            setToBranch("");
        }
    };

    const handleToBranchChange = (e: any) => {
        const selectedToBranch = e.target.value;
        if (selectedToBranch === fromBranch) {
            toast.error("The 'From Branch' and 'To Branch' cannot be the same!");
            setToBranch("");
        } else {
            setToBranch(selectedToBranch);
        }
    };

    if (isLoading || detailsLoading || productsLoading) {
        return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
      }
    
    

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "Inventory", path: "/inventory/transfers" },
                    {
                        label: "Edit Transfer",
                        path: "/inventory/transfers/create",
                        active: true,
                    },
                ]}
                title={"Edit Transfer"}
            />
            <Card>
                <Card.Body>
                    <h4 className="mb-3 header-title mt-0">Edit Transfer</h4>

                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                        isInvalid={!!dateError}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {dateError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>From Branch</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="from_branch_id"
                                        defaultValue={fromBranch}
                                        value={fromBranch}
                                        onChange={handleFromBranchChange}
                                        isInvalid={!!fromBranchError}
                                        required
                                    >
                                        <option value="" disabled>Select Branch</option>
                                        {branches && branches.map((branch: any) => (
                                            <option key={branch.id} value={branch.id}disabled={branch.id === toBranch}>
                                                {branch.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {fromBranchError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={3}>
                                <Form.Group>
                                    <Form.Label>To Branch</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="to_branch_id"
                                        defaultValue={toBranch}
                                        value={toBranch}
                                        onChange={handleToBranchChange}
                                        isInvalid={!!toBranchError}
                                        required
                                    >
                                        <option value="" disabled>Select Branch</option>
                                        {branches && branches.map((branch: any) => (
                                            <option
                                                key={branch.id}
                                                value={branch.id}
                                                disabled={branch.id === fromBranch}
                                            >
                                                {branch.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {toBranchError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="card-primary card-outline">
                                    <Card.Header>
                                        <Row className="mb-1">
                                            <Col className="d-flex justify-content-between align-items-center">
                                                <h4 className="mb-0 header-title mt-0">Products</h4>
                                                <Button
                                                    type="button"
                                                    className="btn btn-primary add_product"
                                                    onClick={handleAddProduct}
                                                >
                                                    <Plus />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <table className="table table-striped" id="products_table">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Quantity</th>
                                                    <th>Note</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productRows.map((row) => (
                                                    <tr key={row.id}>
                                                        <td>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    as="select"
                                                                    name={`products[${row.id}][id]`}
                                                                    value={row.product_id}
                                                                    onChange={(e) => handleProductChange(row.id, 'product_id', e.target.value)}
                                                                    isInvalid={!!productErrors[row.id]}
                                                                    required
                                                                >
                                                                    <option value="" disabled>Select Product</option>
                                                                    {products && products.map((product: any) => (
                                                                        <option key={product.id} value={product.id}>
                                                                            {product.name}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {productErrors[row.id]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    type="number"
                                                                    name={`products[${row.id}][quantity]`}
                                                                    value={row.quantity}
                                                                    min="0"
                                                                    onChange={(e) => handleProductChange(row.id, 'quantity', parseInt(e.target.value))}
                                                                    isInvalid={!!productErrors[row.id]}
                                                                    required
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {productErrors[row.id]}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    name={`products[${row.id}][note]`}
                                                                    value={row.note}
                                                                    rows={1}
                                                                    placeholder="Note"
                                                                    onChange={(e) => handleProductChange(row.id, 'note', e.target.value)}
                                                                />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => handleRemoveProduct(row.id)}
                                                            >
                                                                <Minus />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Button type="submit" className="btn btn-primary" disabled={isPending}>
                           {isPending?"Submitting":"Submit"}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
};

export default EditTransfer;

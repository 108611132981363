import React, { useEffect, useState } from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { transfers as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import { fetchItems } from "../../../helpers/api/ApiService";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../constants/loader";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Date",
    accessor: "date",
    sort: true,
  },
  {
    Header: "From Branch",
    accessor: "from_name",
    sort: false,
  },
  {
    Header: "To Branch",
    accessor: "to_name",
    sort: false,
  },
  {
    Header: "Action", accessor: "action", sort: false, Cell: ({ row }: { row: TableRow<any> }) => (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-action" style={{ borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <i className="bi-gear-wide-connected"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/inventory/transfers/edit/${row.original.id}`}><Edit /> Edit</Dropdown.Item>
          <Dropdown.Item><Trash />Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];



const TransferList = () => {


  const [transfers, settTansfers] = useState<any[]>([]);
  const [isLoading, setisLoading] = useState(false)

  useEffect(() => {
    const fetchTransfers = async () => {
      setisLoading(true);
      try {
        const fetchedTransfers = await fetchItems("/transfers");
        settTansfers(fetchedTransfers);
      } catch (error) {
        toast.error(`Error fetching transfers: ${error}`);
      } finally {
        setisLoading(false);
      }
    };
    fetchTransfers()
  }, [])



  if (isLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  const sizePerPageList = [
    {
      text: "15",
      value: 5,
    },
    {
      text: "25",
      value: 25,
    },

  ];

  if (transfers) {
    sizePerPageList.push({
      text: "All",
      value: transfers.length,
    });
  }


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Advanced Tables"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>

            <div className="page-title-box">
                  <h4 className="page-title">
                    Adjustment List
                  </h4>
                  <div className="page-title-right">
                    <ButtonGroup className="ms-2 d-none d-sm-inline-block me-1">
                      <Link to="/inventory/transfers/create" className="btn btn-primary">
                        <i className="uil uil-plus me-1"></i>Add Transfer
                      </Link>
                    </ButtonGroup>
                  </div>
                </div>

              <Table
                columns={columns}
                data={transfers}
                pageSize={15}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default TransferList;

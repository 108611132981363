import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table';
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { useQuery } from "@tanstack/react-query";
import { fetchItems } from "../../../helpers/api/ApiService";
import { accountsArray as data } from "../data";
import LoadingSpinner from "../../../constants/loader";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Account Name",
    accessor: "name",
    sort: true,
  },
  {
    Header: "Description",
    accessor: "description",
    sort: false,
  },
  {
    Header: "Type",
    accessor: "type",
    sort: true,
  },
  {
    Header: "Date",
    accessor: "date",
    sort: false,
  },
  {
    Header: "Status",
    accessor: "status",
    sort: false,
  },
  {
    Header: "Action",
    accessor: "action",
    sort: false,
    Cell: ({ row }: { row: TableRow<any> }) => (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-action" style={{ borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <i className="bi-gear-wide-connected"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/accounts/edit/${row.original.id}`}><Edit /> Edit</Dropdown.Item>
          <Dropdown.Item><Trash /> Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "All", value: 999999 }, // Set a very high number to show all data
];

const AccountList = () => {
  const {
    data: accounts,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["accounts"],
    queryFn: () => fetchItems("/accounts"),
    staleTime: 5 * 60 * 1000, 
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  console.log(accounts);

  if (isLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          { label: "Advanced Tables", path: "/features/tables/advanced", active: true },
        ]}
        title={"Advanced Tables"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="page-title-box">
                <h4 className="page-title">Account List</h4>
                <div className="page-title-right">
                  <ButtonGroup className="ms-2 d-none d-sm-inline-block me-1">
                    <Link to="/accounts/create" className="btn btn-primary">
                      <i className="uil uil-plus me-1"></i>Add Account
                    </Link>
                  </ButtonGroup>
                </div>
              </div>

              <Table
                columns={columns}
                data={accounts}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AccountList;

import React, { useState, ChangeEvent, FormEvent } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../PageTitle";
import { createItem } from "../../../helpers/api/ApiService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// Define a type for the Account form data
interface AccountFormData {
  name: string;
  description: string;
  type: "debit" | "credit";
  status: "active" | "inactive";
  date: string;
}

const AddAccount: React.FC = () => {

  const [formData, setFormData] = useState<AccountFormData>({
    name: "",
    description: "",
    type: "debit",
    status: "active",
    date: new Date().toISOString().split("T")[0], // Setting current date in YYYY-MM-DD format
  });

  const [errors, setErrors] = useState<Partial<AccountFormData>>({});
  const queryClient = useQueryClient();
  const { mutate: create, isError, isPending } = useMutation({
    mutationFn: (newItem: AccountFormData) => createItem("/accounts", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["accounts"] });
      toast.success("Account created successfully!");
      resetForm();
    },
    onError: (error) => {
      toast.error(`Error creating account: ${(error as Error).message}`);
    },
  });

  // Handler for input changes
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "", // Clear error on input change
    }));
  };

  // Form validation
  const validateForm = () => {
    let formIsValid = true;
    let errors: Partial<AccountFormData> = {};

    if (!formData.name) {
      formIsValid = false;
      errors.name = "Account Name is required";
    }

    setErrors(errors);
    return formIsValid;
  };

  // Handler for form submission
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      create(formData);
    }
  };

  // Function to reset the form fields
  const resetForm = () => {
    setFormData({
      name: "",
      description: "",
      type: "debit",
      status: "active",
      date: new Date().toISOString().split("T")[0],
    });
    setErrors({});
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          { label: "Advanced Tables", path: "/features/tables/advanced", active: true },
        ]}
        title={"Add Account"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Account</h4>

              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="name">Account Name</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="name"
                      id="name"
                      placeholder="Account Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      isInvalid={!!errors.name} // Apply isInvalid if there's an error
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="type">Type</Form.Label>
                  <Col lg={6}>
                    <Form.Select
                      name="type"
                      id="type"
                      value={formData.type}
                      onChange={handleInputChange}
                      isInvalid={!!errors.type} // Apply isInvalid if there's an error
                      required
                    >
                      <option value="debit">Debit</option>
                      <option value="credit">Credit</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.type}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="description">Description</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="description"
                      id="description"
                      placeholder="Description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2}>Status</Form.Label>
                  <Col lg={7}>
                    <Row>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="active"
                          name="status"
                          label="Active"
                          value="active"
                          checked={formData.status === "active"}
                          onChange={handleInputChange}
                          isInvalid={!!errors.status} // Apply isInvalid if there's an error
                        />
                      </Col>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="inactive"
                          name="status"
                          label="Inactive"
                          value="inactive"
                          checked={formData.status === "inactive"}
                          onChange={handleInputChange}
                          isInvalid={!!errors.status} // Apply isInvalid if there's an error
                        />
                      </Col>
                    </Row>
                    {errors.status && (
                      <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                        {errors.status}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isPending}>
                  {isPending ? "Submitting..." : "Submit"}
                </Button>
                {isError && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    There was an error creating the account. Please try again.
                  </p>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddAccount;

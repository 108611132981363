interface DownloadFile {
  data: any;
  filename: string;
  mime: any;
  bom: any;
}

const downloadFile = ({ data, filename, mime, bom }: DownloadFile) => {
  var blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  var blob = new Blob(blobData, { type: mime || "application/octet-stream" });

  var blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);
  var tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobURL;
  tempLink.setAttribute("download", filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }, 200);
};


// utils/numberToWordsBDT.ts

const units = [
  "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
];

const teens = [
  "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"
];

const tens = [
  "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
];

const scales = [
  "", "Thousand", "Lakh", "Crore"
];

const convertChunkToWords = (chunk: number): string => {
  let words = '';
  
  if (chunk >= 100) {
      words += units[Math.floor(chunk / 100)] + " Hundred ";
      chunk %= 100;
  }

  if (chunk >= 20) {
      words += tens[Math.floor(chunk / 10)] + " ";
      chunk %= 10;
  } else if (chunk >= 10) {
      words += teens[chunk - 10] + " ";
      chunk = 0;
  }

  if (chunk > 0) {
      words += units[chunk] + " ";
  }

  return words.trim();
};

const getEnglishNumberWords = (num: number): string => {
  if (num === 0) return "Zero";

  let words = '';
  let scale = 0;

  while (num > 0) {
      const chunk = num % 1000;
      if (chunk) {
          let chunkWords = convertChunkToWords(chunk);
          words = chunkWords + " " + scales[scale] + " " + words;
      }
      num = Math.floor(num / 1000);
      scale++;
  }

  return words.trim();
};

export const numberToWordsBDT = (amount: number): string => {
  const integerPart = Math.floor(amount);
  const decimalPart = Math.round((amount - integerPart) * 100);

  const integerWords = getEnglishNumberWords(integerPart);
  const decimalWords = decimalPart > 0 ? getEnglishNumberWords(decimalPart) : '';

  return decimalPart > 0
      ? `${integerWords} Taka and ${decimalWords} Paisa`
      : `${integerWords} Taka`;
};


export { downloadFile };

import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer } from '@react-pdf/renderer';
import LoadingSpinner from '../constants/loader';
import { fetchItem } from '../helpers/api/ApiService';
import { toast } from 'react-toastify';
import { Row } from 'react-bootstrap';
import { numberToWordsBDT } from '../helpers/api/utils';

interface Invoice {
    barcode: string;
    date: string;
    delivery_date: string;
    patient_code: string;
    patient_name: string;
    gender: string;
    doctor_name: string;
    subtotal:number;
    total:number;
    paid:number;
    due:number;
    discount_amount:number;
    discount_percent:number;
    tests: { name: string; price: string }[];
}

interface CalculationItemProps {
    name: string;
    amount: number;
}

const CalculationItem: React.FC<CalculationItemProps> = ({ name, amount }) => (
    <View style={[styles.rowH, { width: "100%", paddingVertical: 6 }]}>
        <Text style={[styles.value, { textAlign: "left", width: "50%", fontSize: "10" }]}>{name}</Text>
        <Text style={[styles.value, { width: 5, fontSize: "10" }]}>:</Text>
        <Text style={[styles.value, { width: "50%", textAlign: "right", fontSize: "10", paddingRight: 16 }]}>{amount}</Text>
    </View>
);

interface TestItemRowProps {
    sl: number;
    testName: string;
    amount: number;
}

// Test Item Row Component
const TestItemRow: React.FC<TestItemRowProps> = ({ sl, testName, amount }) => (
    <View style={[styles.row, { borderBottomWidth: 1, borderBottomStyle: 'dotted', borderBottomColor: "grey", padding: 4 }]}>
        <Text style={[styles.label, { width: "10%", textAlign: "left", paddingLeft: 12 }]}>
            {sl}
        </Text>
        <Text style={[styles.label, { width: "65%", textAlign: "left", paddingLeft: 12 }]}>
            {testName}
        </Text>
        <Text style={[styles.label, { width: "25%", textAlign: "right", paddingRight: 12 }]}>
            {amount.toFixed(2)}
        </Text>
    </View>
);

const RenderPdf: React.FC = () => {
    const [isLoading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState<Invoice | null>(null);

    useEffect(() => {
        const accountId = parseInt("14");

        const fetchInvoice = async () => {
            setLoading(true);
            try {
                const fetchedInvoice = await fetchItem("/invoice-pdf", accountId);
                setInvoice(fetchedInvoice);
            } catch (error) {
                toast.error(`Error fetching account: ${error}`);
            } finally {
                setLoading(false);
            }
        };

        fetchInvoice();
    }, []);

    if (isLoading || !invoice) {
        return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner /></Row>;
    }

    return (
        <>
            <PDFViewer style={{ width: "100%", height: "100vh" }}>
                <Document>
                    <Page style={styles.page} orientation="portrait">
                        <View style={styles.header} fixed>
                            <Text style={styles.headerText}>Company Logo</Text> {/* Placeholder for a company logo or header text */}
                        </View>
                        <View style={styles.body}>

                            <View style={[{ borderWidth: 1, borderColor: "grey", padding: "3" }]}>
                                <View style={styles.row}>
                                    <View style={[styles.rowH, { width: "30%" }]}>
                                        <Text style={[styles.label, { textAlign: "left" }]}>Invoice No</Text>
                                        <Text style={[styles.value, { textAlign: "left", marginLeft: "10" }]}>: {invoice?.barcode}</Text>
                                    </View>
                                    <View style={styles.rowH}>
                                        <Text style={[styles.label, { width: "50%", textAlign: 'right' }]}>Invoice Date</Text>
                                        <Text style={[styles.value, { width: "50%" }]}>: {invoice?.date}</Text>
                                    </View>
                                    <View style={[styles.rowH, { width: "24%" }]}>
                                        <Text style={[styles.label, { width: "50%", textAlign: 'right' }]}>Delivery Date</Text>
                                        <Text style={[styles.value, { width: "50%", textAlign: "left", marginLeft: "10" }]}>: {invoice?.delivery_date}</Text>
                                    </View>
                                    <View style={[styles.rowH, { width: "24%" }]}>
                                        <Text style={[styles.label, { textAlign: "right" }]}>Patient ID</Text>
                                        <Text style={[styles.value, { textAlign: "left", paddingLeft: 10 }]}>: {invoice?.patient_code}</Text>
                                    </View>
                                </View>

                                <View style={styles.row}>
                                    <View style={[styles.rowH, { width: "52%" }]}>
                                        <Text style={[styles.label, { textAlign: "left", width: "21.8%" }]}>Patient Name</Text>
                                        <Text style={[styles.value, { width: "78.2%", textAlign: "left", marginLeft: "10" }]}>: {invoice?.patient_name}</Text>
                                    </View>

                                    <View style={[styles.rowH, { width: "24%" }]}>
                                        <Text style={[styles.label, { width: "50%", textAlign: 'right' }]}>Gender</Text>
                                        <Text style={[styles.value, { width: "50%", textAlign: "left", marginLeft: "10" }]}>: {invoice?.gender}</Text>
                                    </View>
                                    <View style={[styles.rowH, { width: "24%" }]}>
                                        <Text style={[styles.label, { textAlign: "right" }]}>Age</Text>
                                        <Text style={[styles.value, { textAlign: "left", paddingLeft: 10 }]}>: 32</Text>
                                    </View>
                                </View>

                                <View style={[styles.row, { marginBottom: 0 }]}>
                                    <View style={[styles.rowH, { width: "100%" }]}>
                                        <Text style={[styles.label, { textAlign: "left", width: "11.2%" }]}>Referred By</Text>
                                        <Text style={[styles.value, { width: "87.8%", textAlign: "left", marginLeft: "10" }]}>: {invoice?.doctor_name}</Text>
                                    </View>
                                </View>

                            </View>

                            <View style={[{ height: 5 }]}></View>

                            <View style={[styles.row, { borderWidth: 1, borderColor: "grey" }]}>
                                <Text style={[styles.value, { width: "10%", textAlign: "center" }]}>SL</Text>
                                <View style={styles.separator}></View>
                                <Text style={[styles.value, { width: "65%", textAlign: "center" }]}>Test Name</Text>
                                <View style={styles.separator}></View>
                                <Text style={[styles.value, { width: "25%", textAlign: "center" }]}>Amount</Text>
                            </View>

                            <View style={[{ height: 5 }]}></View>
                            
                            {invoice.tests.map((test, index) => (
                                <TestItemRow key={index} sl={index + 1} testName={test.name} amount={parseFloat(test.price)} />
                            ))}

                            <View style={[{ flexDirection: "column", width: "40%", alignSelf: "flex-end", }]}>
                                <CalculationItem name={"Subtotal Amount"} amount={invoice.subtotal} />
                                <CalculationItem name={`Discount ${invoice.discount_percent}`} amount={invoice.discount_amount} />
                                <CalculationItem name={"Total Amount"} amount={invoice.total} />
                                <CalculationItem name={"Paid Amount"} amount={invoice.paid} />
                                <CalculationItem name={"Due"} amount={invoice.due} />
                            </View>
                            <Text style={[styles.label, { width: "100%", textAlign: "left", fontSize: "10" }]}>In Words: {numberToWordsBDT(invoice.total)}</Text>
                        </View>

                        <View style={styles.footer} fixed>
                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                                `${pageNumber} / ${totalPages}`
                            )} fixed />
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </>
    );
};

Font.register({
    family: 'Arima',
    fonts: [
        {
            src: 'https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1YTA-pQGOyYw2fw.ttf',
        },
        {
            src: 'https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1hjc-pQGOyYw2fw.ttf',
            fontWeight: 'bold',
        },
        {
            src: 'https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-ERBrEdwcoaKww.ttf',
            fontWeight: 'normal',
            fontStyle: 'italic',
        },
    ],
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 0,
        paddingBottom: 60,
    },
    body: {
        paddingBottom: 60,
        paddingHorizontal: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        flexDirection: 'row',
        marginBottom: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    rowH: {
        width: "22%",
        flexDirection: 'row',
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    label: {
        fontSize: 9,
        width: "40%",
        fontFamily: 'Arima',
        textAlign: 'center',
    },
    value: {
        fontSize: 8,
        width: "60%",
        fontWeight: 'bold',
        fontFamily: 'Arima',
        textAlign: 'center',
    },
    header: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 80,
        marginBottom: 15,
        backgroundColor: "grey",
    },
    headerText: {
        fontSize: 16,
        color: 'white',
        fontFamily: 'Arima',
        textAlign: 'center',
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 40,
        backgroundColor: 'grey',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pageNumber: {
        fontSize: 12,
        color: 'white',
        textAlign: 'center',
    },
    separator: {
        width: 0.5,
        backgroundColor: 'grey',
        height: 18,
    },
});

export default RenderPdf;

import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { invoiceList as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import { useQuery } from "@tanstack/react-query";
import { fetchItems } from "../../../helpers/api/ApiService";
import LoadingSpinner from "../../../constants/loader";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Patient ID",
    accessor: "patient_id",
    sort: true,
    Footer: false,
  },
  {
    Header: "Total",
    accessor: "total",
    sort: false,
    Footer: true,
  },
  {
    Header: "Vat",
    accessor: "vat",
    sort: true,
  },
  {
    Header: "Discount",
    accessor: "discount",
    sort: false,
  },
  {
    Header: "Grand Total",
    accessor: "grand_total",
    sort: false,
  },
  {
    Header: "Paid",
    accessor: "paid",
    sort: false,
  },
  {
    Header: "Due",
    accessor: "due",
    sort: false,
  },
  {
    Header: "Status",
    accessor: "status",
    sort: false,
  },
  {
    Header: "Action", accessor: "action", sort: false, Cell: ({ row }: { row: TableRow<any> }) => (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-action" style={{ borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <i className="bi-gear-wide-connected"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/accounts/invoice/edit/${row.original.id}`}><Edit /> Edit</Dropdown.Item>
          <Dropdown.Item><Trash />Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

const sizePerPageList = [
  {
    text: "5",
    value: 5,
  },
  {
    text: "10",
    value: 10,
  },
  {
    text: "25",
    value: 25,
  },
  {
    text: "All",
    value: data.length,
  },
];

const InvoiceList = () => {


  const {
    data: invoices,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["account-invoices"],
    queryFn: () => fetchItems("/account-invoices"),
    staleTime: 5 * 60 * 1000, 
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  // console.log(invoices);
  // if(invoices){
  //   invoices.pop();
  // }

  if (isLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Invoice List"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="page-title-box">
                <h4 className="page-title">
                  Invoice List
                </h4>
                <div className="page-title-right">
                  <ButtonGroup className="ms-2 d-none d-sm-inline-block me-1">
                    <Link to="/accounts/invoice/create" className="btn btn-primary">
                      <i className="uil uil-plus me-1"></i>Add Invoice
                    </Link>
                  </ButtonGroup>
                </div>
              </div>

              <Table
                columns={columns}
                data={invoices}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
                haveFooter={false}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default InvoiceList;

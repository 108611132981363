import React, { useState, useEffect, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import PageTitle from "../../PageTitle";
import { Supplier, suppliers } from "../data"; // Import your supplier data and type
import { toast } from "react-toastify";
import { createItem, fetchItem, updateItem } from "../../../helpers/api/ApiService";
import LoadingSpinner from "../../../constants/loader";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface FormErrors {
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
}

const EditSupplier: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [supplier, setSupplier] = useState<Supplier | undefined>(undefined);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    const accountId = parseInt(id!);

    const fetchAccount = async () => {
      setLoading(true);
      try {
        const fetchedSupplier = await fetchItem("/suppliers", accountId);
        setSupplier(fetchedSupplier);
      } catch (error) {
        toast.error(`Error fetching account: ${error}`);
        navigate("/suppliers");
      } finally {
        setLoading(false);
      }
    };

    fetchAccount();
  }, [id, navigate]);





  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (supplier) {
      setSupplier({ ...supplier, [name]: value });
    }
  };


  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const validateForm = (): FormErrors => {
    const errors: FormErrors = {};

    if (!supplier?.name.trim()) {
      errors.name = "Name is required";
    }

    if (!supplier?.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(supplier?.email)) {
      errors.email = "Invalid email address";
    }

    if (!supplier?.phone.trim()) {
      errors.phone = "Phone number is required";
    }

    // if (!formData.address.trim()) {
    //   errors.address = "Address is required";
    // }

    return errors;
  };

  const queryClient = useQueryClient();
  const { mutate: update, isError, isPending } = useMutation({
    mutationFn: (newItem: Supplier) => updateItem("/suppliers", parseInt(id!), newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["suppliers"] });
      toast.success("supplier updated successfully!");
      setFormErrors({});
      navigate("/inventory/suppliers");
    },
    onError: (error) => {
      toast.error(`Error updating supplier: ${(error as Error).message}`);
    },
  });

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      if (supplier) {
        console.log('Form data submitted:', supplier);
        update(supplier!);
      }else{
        setFormErrors(errors);
      }
    } else {
      setFormErrors(errors);
    }
  };

  if (isLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Edit Supplier"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Edit Supplier</h4>

              <Form onSubmit={handleFormSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="name">Name</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={supplier?.name}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="email">Email</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="email"
                      type="email"
                      id="email"
                      placeholder="Email"
                      value={supplier?.email}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="phone">Phone</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="phone"
                      id="phone"
                      placeholder="Phone"
                      value={supplier?.phone}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="address">Address</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="address"
                      id="address"
                      placeholder="Address"
                      value={supplier?.address}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isPending}>
                  {isPending ? "Updating Supplier..." : "Update Supplier"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditSupplier;

import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { Branch, branches as data } from "../data"; // Adjust the import based on your file structure
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createItem, fetchItem, fetchItems, updateItem } from "../../../helpers/api/ApiService";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../constants/loader";
import { useNavigate, useParams } from "react-router-dom";

const EditProduct = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState({
    "id": -1,
    "name": "",
    "sku": "",
    "description": "",
    "stocks": [
    ]
  });


  // State for main product details
  const [formData, setFormData] = useState({
    id:-1,
    name: '',
    sku: '',
    description: '',
    stock:[]
  });

  // State for branch-specific data
  const [branchData, setBranchData] = useState(
    data.map(branch => ({
      branch_id: branch.id,
      name: branch.name,
      initial_quantity: 0,
      stock_alert: 0,
    }))
  );

  // State for form errors
  const [formErrors, setFormErrors] = useState({
    name: '',
    sku: '',
    description: ''
  });


  const {
    data: branches,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["branches"],
    queryFn: () => fetchItems("/branches"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
  interface BranchS {
    id: number;
    name: string;
    initial_quantity: number;
    stock_alert: number;
    branch_id: number; // Assuming branch_id is different from id and used in your logic
  }
  const handleBranchChangeInIt = (stock_branches:BranchS[]) => {
    console.log(stock_branches);
    setBranchData(prevBranchData =>
      prevBranchData.map(branch => {
        const updatedBranch = stock_branches.find((item: BranchS) => item.branch_id === branch.branch_id);
        if (updatedBranch) {
          return {
            ...branch,
            initial_quantity: updatedBranch.initial_quantity,
            stock_alert: updatedBranch.stock_alert,
          };
        }
        return branch;
      })
    );
  };
  
  useEffect(() => {
    const p_id = parseInt(id!);

    const fetchProducts = async () => {
      setDetailsLoading(true);
      try {
        const fetchedProduct = await fetchItem("/products", p_id);
        setFormData(fetchedProduct);
        handleBranchChangeInIt(fetchedProduct.stocks)
        console.log(formData);
      } catch (error) {
        toast.error(`Error fetching account: ${error}`);
        navigate("/inventory/products");
      } finally {
        setDetailsLoading(false);
      }
    };

    fetchProducts();
  }, [id, navigate]);


  useEffect(() => {
    if (branches) {
      setBranchData(
        branches.map((branch: {
          id: any; name: any;
        }) => ({
          branch_id: branch.id,
          name: branch.name,
          initial_quantity: 0,
          stock_alert: 0,
        }))
      );
    }
  }, [branches])


  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleBranchChange = (e: any, index: number, field: string) => {
    const value = Number(e.target.value);
    setBranchData(prevBranchData => {
      const updatedBranchData = [...prevBranchData];
      updatedBranchData[index] = {
        ...updatedBranchData[index],
        [field]: value
      };
      return updatedBranchData;
    });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      name: '',
      sku: '',
      description: '',
      branches: ''
    };

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      toast.error(errors.name);
      isValid = false;
    }

    if (!formData.sku.trim()) {
      errors.sku = "SKU is required";
      toast.error(errors.sku);
      isValid = false;
    }

    if (!formData.description.trim()) {
      errors.description = "Description is required";
      toast.error(errors.description);
      isValid = false;
    }

    const hasValidQuantity = branchData.some(branch => branch.initial_quantity > 0);
    if (!hasValidQuantity) {
      errors.branches = "At least one branch must have an initial quantity greater than 0.";
      toast.error("At least one branch must have an initial quantity greater than 0.");
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const queryClient = useQueryClient();
  const { mutate: update, isError, isPending } = useMutation({
    mutationFn: (newItem: any) => updateItem("/products",parseInt(id!), newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      toast.success("Product added successfully!");
      navigate("/inventory/products");
    },
    onError: (error) => {
      toast.error(`Error creating Product: ${(error as Error).message}`);
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {

      const validBranches = branchData.filter(branch => branch.initial_quantity > 0);

      const newProduct = {
        ...formData,
        stocks: validBranches
      };
      update(newProduct);

    }
  };


  if (isLoading || detailsLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }



  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Product"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Product</h4>

              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={"6"} className="mb-3">
                    <Form.Group>
                      <Form.Label htmlFor="name">Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={"6"} className="mb-3">
                    <Form.Group>
                      <Form.Label htmlFor="sku">SKU</Form.Label>
                      <Form.Control
                        type="text"
                        name="sku"
                        id="sku"
                        placeholder="SKU"
                        value={formData.sku}
                        onChange={handleInputChange}
                        isInvalid={!!formErrors.sku}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formErrors.sku}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="description">Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="description"
                    id="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={handleInputChange}
                    isInvalid={!!formErrors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.description}
                  </Form.Control.Feedback>
                </Form.Group>

                <Card>
                  <Card.Body>
                    <h4>Branches</h4>
                    <Row className="mb-3">
                      <Col md={"4"} className="text-center fw-bold">Branch Name</Col>
                      <Col md={"4"} className="text-center fw-bold">Initial Quantity</Col>
                      <Col md={"4"} className="text-center fw-bold">Stock Alert</Col>
                    </Row>
                    {branchData.map((branch, index) => (
                      <Row key={index}>
                        <Row  className="mb-3">
                          <Col md={"4"}>{branch.name}</Col>
                          <Col md={"4"}>
                            <Form.Control
                              type="number"
                              name="initial_quantity"
                              defaultValue={branch.initial_quantity}
                              onChange={(e) => handleBranchChange(e, index, 'initial_quantity')}
                              placeholder="Initial Quantity"
                              min={0}
                            />
                          </Col>
                          <Col md={"4"}>
                            <Form.Control
                              type="number"
                              name="stock_alert"
                              defaultValue={branch.stock_alert}
                              onChange={(e) => handleBranchChange(e, index, 'stock_alert')}
                              placeholder="Stock Alert"
                              min={0}
                            />
                          </Col>
                        </Row>
                        {index !== (branchData.length - 1) && <hr />}
                      </Row>
                    ))}
                  </Card.Body>
                </Card>

                <Button variant="primary" type="submit" disabled={isPending}>
                  {isPending ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditProduct;

import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { suppliers as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import { useQuery } from "@tanstack/react-query";
import { fetchItems } from "../../../helpers/api/ApiService";
import LoadingSpinner from "../../../constants/loader";
import { toast } from "react-toastify";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Name",
    accessor: "name",
    sort: true,
  },
  {
    Header: "Email",
    accessor: "email",
    sort: false,
  },
  {
    Header: "Phone",
    accessor: "phone",
    sort: true,
  },
  {
    Header: "Total",
    accessor: "total",
    sort: false,
  },
  {
    Header: "Paid",
    accessor: "paid",
    sort: false,
  },
  {
    Header: "Due",
    accessor: "due",
    sort: false,
  },
  {
    Header: "Action", accessor: "action", sort: false, Cell: ({ row }: { row: TableRow<any> }) => (
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-action" style={{ borderRadius: "50%", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <i className="bi-gear-wide-connected"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/inventory/suppliers/edit/${row.original.id}`}><Edit /> Edit</Dropdown.Item>
          <Dropdown.Item><Trash />Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];


const SupplierList = () => {

  const {
    data: suppliers,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["suppliers"],
    queryFn: () => fetchItems("/suppliers"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  console.log(suppliers);

  if (isLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  if (error) {
    toast.error(`Error: ${error.message}`);
    return <p>Error: {error.message}</p>;
  }
  const sizePerPageList = [
    {
      text: "15",
      value: 5,
    },
    {
      text: "25",
      value: 25,
    },
  
  ];
  
  if (suppliers) {
    sizePerPageList.push({
      text: "All",
      value: suppliers.length,
    });
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Advanced Tables"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>

              <div className="page-title-box">
                <h4 className="page-title">
                  Supplier List
                </h4>
                <div className="page-title-right">
                  <ButtonGroup className="ms-2 d-none d-sm-inline-block me-1">
                    <Link to="/inventory/suppliers/create" className="btn btn-primary">
                      <i className="uil uil-plus me-1"></i>Add Supplier
                    </Link>
                  </ButtonGroup>
                </div>
              </div>

              <Table
                columns={columns}
                data={suppliers}
                pageSize={15}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default SupplierList;

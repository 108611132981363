import React from "react";
import { Row, Col, Card, Dropdown, ButtonGroup } from "react-bootstrap";
import { Cell, Row as TableRow } from 'react-table'; // Import Cell and Row types
import PageTitle from "../../PageTitle";
import Table from "../../Table";
import { reportList as data } from "../data";
import { Link } from "react-router-dom";
import { Edit, Trash } from 'feather-icons-react';
import { useQuery } from "@tanstack/react-query";
import { fetchItems } from "../../../helpers/api/ApiService";
import LoadingSpinner from "../../../constants/loader";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: true,
  },
  {
    Header: "Patient ID",
    accessor: "patient_id",
    sort: true,
    Footer: false,
  },
  {
    Header: "Total",
    accessor: "total",
    sort: false,
    Footer: true,
  },
  {
    Header: "Vat",
    accessor: "vat",
    sort: true,
  },
  {
    Header: "Discount",
    accessor: "discount",
    sort: false,
  },
  {
    Header: "Grand Total",
    accessor: "grand_total",
    sort: false,
  },
  {
    Header: "Paid",
    accessor: "paid",
    sort: false,
  },
  {
    Header: "Due",
    accessor: "due",
    sort: false,
  },
  
];
const sizePerPageList = [
  {
    text: "15",
    value: 15,
  },
  // {
  //   text: "10",
  //   value: 10,
  // },
  {
    text: "25",
    value: 25,
  },
 
];

const AccountReport = () => {

  const {
    data: invoices,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["accounts-report"],
    queryFn: () => fetchItems("accounts/reports"),
    staleTime: 5 * 60 * 1000, 
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });



  if (isLoading) {
    return <Row className="mt-4 mb-4 " style={{ height: "100vh" }}><LoadingSpinner></LoadingSpinner></Row>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if(invoices){
    sizePerPageList.push( {
      text: "All",
      value: invoices.length,
    });
  }

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Account Report"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="page-title-box">
                <h4 className="page-title">
                Account Report
                </h4>
              </div>

              <Table
                columns={columns}
                data={invoices}
                pageSize={15}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                isSearchable={true}
                haveFooter={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default AccountReport;

import React, { useState, ChangeEvent, FormEvent } from "react";
import { Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import PageTitle from "../../PageTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createItem } from "../../../helpers/api/ApiService";
import { toast } from "react-toastify";

interface FormData {
  name: string;
  email: string;
  phone: string;
  address: string;
}

interface FormErrors {
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
}

const AddSupplier: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    address: ''
  });


  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const validateForm = (): FormErrors => {
    const errors: FormErrors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    }

    // if (!formData.address.trim()) {
    //   errors.address = "Address is required";
    // }

    return errors;
  };

  const queryClient = useQueryClient();
  const { mutate: create, isError, isPending } = useMutation({
    mutationFn: (newItem: FormData) => createItem("/suppliers", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["suppliers"] });
      toast.success("Supplier created successfully!");
      setFormData({
        name: '',
        email: '',
        phone: '',
        address: ''
      });
      setFormErrors({});
    },
    onError: (error) => {
      toast.error(`Error creating supplier: ${(error as Error).message}`);
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      console.log('Form data submitted:', formData);
      create(formData);
    } else {
      setFormErrors(errors);
    }
  };



  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Supplier"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Supplier</h4>

              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="name">Name</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      isInvalid={!!formErrors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="email">Email</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="email"
                      type="email"
                      id="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      isInvalid={!!formErrors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="phone">Phone</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      name="phone"
                      id="phone"
                      type="phone"
                      placeholder="Phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      isInvalid={!!formErrors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.phone}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="address">Address</Form.Label>
                  <Col lg={6}>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="address"
                      id="address"
                      placeholder="Address"
                      value={formData.address}
                      onChange={handleInputChange}
                      isInvalid={!!formErrors.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.address}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {Object.keys(formErrors).length > 0 && (
                  <Alert variant="danger">
                    Please fix the above errors before submitting the form.
                  </Alert>
                )}

                <Button variant="primary" type="submit" disabled={isPending}>
                  {isPending ? "Submitting..." : "Submit"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddSupplier;

import React, { useState, useEffect, ChangeEvent } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import PageTitle from "../../PageTitle";
import { createItem, fetchItems } from "../../../helpers/api/ApiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from 'react-toastify';

interface Account {
  id: number;
  name: string;
  description: string;
}

const AddPayment = () => {
  const [currentDate, setCurrentDate] = useState<string>('');
  const [selectedAccount, setSelectedAccount] = useState<{ id: number, name: string }>({ id: 0, name: '' });
  const [payTo, setPayTo] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [status, setStatus] = useState<string>('active');
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const { data: accounts, error: accountsError, isLoading: isAccountLoading } = useQuery<Account[]>({
    queryKey: ["accounts"],
    queryFn: () => fetchItems("/accounts"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    getCurrentDate();
  }, []);

  const getCurrentDate = () => {
    const date = new Date().toISOString().split('T')[0];
    setCurrentDate(date);
  };

  const handleValidation = () => {
    let errors: { [key: string]: string } = {};

    if (!selectedAccount.id) {
      errors.accountName = "Account name is required";
    }

    if (!payTo) {
      errors.payTo = "Pay To field is required";
    }

    if (!amount || amount <= 0) {
      errors.amount = "Amount must be greater than zero";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const queryClient = useQueryClient();
  const { mutate: create, isError, isPending } = useMutation({
      mutationFn: (newItem: any) => createItem("/payments", newItem),
      onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["payments"] });
          toast.success("Payment created successfully!");
          resetForm();
      },
      onError: (error) => {
          toast.error(`Error creating Payment: ${(error as Error).message}`);
      },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (handleValidation()) {

      const data={
        date:currentDate,
        account_id: selectedAccount.id,
        account_name: selectedAccount.name,
        pay_to:payTo,
        description,
        amount,
        status:status=="active"?true:false,
      };
      console.log("Form submitted:", data);
      create(data);
    } else {
      toast.error(`Form has errors. Please correct them.${formErrors}`);
    }
  };

  const resetForm = () => {
    setCurrentDate('');
    setSelectedAccount({ id: 0, name: '' });
    setPayTo('');
    setDescription('');
    setAmount(undefined);
    setStatus('active');
    setFormErrors({});
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Tables", path: "/features/tables/advanced" },
          {
            label: "Advanced Tables",
            path: "/features/tables/advanced",
            active: true,
          },
        ]}
        title={"Add Payment"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="mb-3 header-title mt-0">Add Payment</h4>

              <Form onSubmit={handleSubmit}>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="date">
                    Date
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Control id="date" type="date" name="date" value={currentDate} readOnly />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="accountName">
                    Account Name
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Select
                      id="accountName"
                      name="accountName"
                      value={selectedAccount.id.toString()}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        const selectedId = parseInt(e.target.value);
                        const selectedName = accounts?.find(acc => acc.id === selectedId)?.name || '';
                        setSelectedAccount({ id: selectedId, name: selectedName });
                      }}
                      className="dont-select-me"
                      required
                      isInvalid={!!formErrors.accountName}
                    >
                      <option value="0" disabled>
                        Select Option
                      </option>
                      {accounts?.map((acc: Account) => (
                        <option value={acc.id.toString()} key={acc.id}>{acc.name}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formErrors.accountName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="payTo">
                    Pay To
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Control
                      id="payTo"
                      name="payTo"
                      value={payTo}
                      onChange={(e) => setPayTo(e.target.value)}
                      placeholder="Pay To"
                      isInvalid={!!formErrors.payTo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.payTo}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="description">
                    Description
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      id="description"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Description"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2} htmlFor="amount">
                    Amount
                  </Form.Label>
                  <Col lg={7}>
                    <Form.Control
                      id="amount"
                      type="number"
                      name="amount"
                      value={amount || ''}
                      onChange={(e) => setAmount(parseFloat(e.target.value))}
                      isInvalid={!!formErrors.amount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formErrors.amount}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column lg={2}>
                    Status
                  </Form.Label>
                  <Col lg={7}>
                    <Row>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="active"
                          name="status"
                          label="Active"
                          checked={status === 'active'}
                          onChange={() => setStatus('active')}
                        />
                      </Col>
                      <Col md={"auto"}>
                        <Form.Check
                          type="radio"
                          className="mb-1"
                          id="inactive"
                          name="status"
                          label="Inactive"
                          checked={status === 'inactive'}
                          onChange={() => setStatus('inactive')}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>

                <Button variant="primary" type="submit" disabled={isPending}>
                 {isPending?"Submitting...":"Submit"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default AddPayment;

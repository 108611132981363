import axios from "axios";

const API_BASE_URL = "https://api.sohabagcluster.com/api";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchItems = async (endPoint: string): Promise<any> => {
  try {
    const response = await api.get(`${endPoint}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw new Error("Could not fetch items.");
  }
};

export const fetchItem = async (endPoint: string, id: number): Promise<any> => {
  console.log(`${endPoint}/${id}`);
  try {
    const response = await api.get(`${endPoint}/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching item with id ${id}:`, error);
    throw new Error(`Could not fetch item with id ${id}.`);
  }
};

export const createItem = async (endPoint: string, data: any): Promise<any> => {
  try {
    const response = await api.post(`${endPoint}`, data);
    return response.data;
  } catch (error) {
    console.error("Error creating item:", error);
    throw new Error("Could not create item.");
  }
};

export const updateCheckOut = async (endPoint: string): Promise<any> => {
  try {
    const response = await api.post(`${endPoint}`);
    return response.data;
  } catch (error) {
    console.error("Error creating item:", error);
    throw new Error("Could not create item.");
  }
};

export const updateItem = async (
  endPoint: string,
  id: number,
  data: any
): Promise<any> => {
  try {
    const response = await api.put(`${endPoint}/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating item with id ${id}:`, error);
    throw new Error(`Could not update item with id ${id}.`);
  }
};

export const deleteItem = async (endPoint: string): Promise<void> => {
  try {
    await api.delete(`${endPoint}`);
  } catch (error) {
    console.error(`Error deleting item at ${endPoint}:`, error);
    throw new Error("Could not delete item.");
  }
};
